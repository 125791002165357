
export const Progress = [
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    Progress: [4,5],
                    }],
        Text:  "[NAME] has picked up concepts quickly and applied [NAMECONNECT] [DEVELOPED] skills to class challenges."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Progress: [1,2],
                    }],
        Text:  "[NAME]'s progress has been disappointing this [TIME], [NAME] is certainly capable of more.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [1,2],
                    }],
        Text:  "[NAME]'s progress has been disappointing this [TIME].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2,3,4,5],
                    Progress: [1,2,3,4,5],
                    }],
        Text:  "[NAME] has made [PROGRESS] progress.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
            Attainment: [3,4,5],
            Progress: [3,4,5],
            },
            {
                Attainment: [1,2,3],
                Progress: [1,2,3],
            }],
        Text:  "[NAME] has made [PROGRESS] progress in [SUBJECT].",
    },
    {
        ReportType: ['General','Science'],
        Position: 'Intro',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
            Attainment: [3,4,5],
            Progress: [3,4,5],
            },
            {
                Attainment: [1,2,3],
                Progress: [1,2,3],
            }],
        Text:  "[NAME] has made [PROGRESS] progress across the curriculum.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
            {
                Attainment: [1,2],
                Progress: [1,2],
            }],
        Text:  "[NAME] has had a disappointing [TIME].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
            {
                Attainment: [1,2],
                Progress: [1,2],
            }],
        Text:  "[NAME]'s development this [TIME] has been limited.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Exam'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [
            {
                Attainment: [1,2],
                Progress: [1,2],
            }],
        Text:  "I have been disappointed by the progress [NAME] has made.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Exam'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [
            {
                Progress: [1,2],
                Attainment: [1,2],
            }],
        Text:  "I have disappointed that [NAME] has been unable to make good progress.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [1,2]
                    }],
        Text:     "[NAME] has had a difficult [TIME] in [SUBJECT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [4,5],
                    }],
        Text:  "[NAME] has done very well [DEVELOPING] [NAMECONNECT] skills this [TIME].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] progress has been [PROGRESS].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] should be very encouraged by [NAMECONNECT] progress and not be too disheartened by [NAMECONNECT] current ability which will improve if this path continues.",
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [1,2],
                    Effort: [1,2,3],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] progress has been limited and [NAME] will need to apply additional effort to catch up next [TIME].",
    },
    {
        ReportType: ['General'],
        Position: 'ALL',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    }],
        Text:  "[NAME] should be very proud of how much [NAME] has learnt and [DEVELOPED].",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAMECONNECT] [STRSKILLS] skills have [DEVELOPED] [PROGRESSDESC].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'ALL',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] has [DEVELOPED] [NAMECONNECT] understanding of [STRTOPICS] [PROGRESSDESC].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAMECONNECT] understanding of [STRTOPICS] have [DEVELOPED] [PROGRESSDESC].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has learnt and refined [NAMECONNECT] skills when completing [OTHWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2],

                    }],
        Text:  "[NAME] has sadly not [DEVELOPED] much this [TIME]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2]
                    }],
        Text:  "[NAME] has struggled to [DEVELOP] [NAMECONNECT] [WKSKILLS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2]
                    }],
        Text:  "[NAME] has struggled to [DEVELOP] [NAMECONNECT] understanding of [WKTOPICS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] should be pleased with the knowledge of [STRTOPICS] [NAME] has developed."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [1,2]
                    }],
        Text:  "[NAME] should be pleased with the progress [NAME] has made."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] should be pleased with the [STRSKILLS] skills [NAME] has [DEVELOPED]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "It has been wonderful to see [NAMEREF] [DEVELOP] [NAMECONNECT] [STRSKILLS] skills."
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "It has been wonderful to see [NAMEREF] [DEVELOP] [NAMECONNECT] understanding of [STRTOPICS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "It has been wonderful to see [NAMEREF] [DEVELOP] a range of skills."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Engagement: [4,5],
                    }],
        Text:  "It has been wonderful to see [NAMEREF] [DEVELOP] [NAMECONNECT] understanding and interest in [SUBJECT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    }],
        Text:  "[NAME] should be very proud of how much [NAME] has learnt and [DEVELOPED] over the [TIME]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] has shown [PROGRESS] progress with [NAMECONNECT] [STRSKILLS] skills in particular showing significant [DEVELOPMENT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Engagement: [4,5],
                    Effort: [3,4,5]
                    }],
        Text:  "[NAME] has certainly grown in confidence."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    }],
        Text:  "[NAMECONNECT] progress has been [PROGRESS]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has [DEVELOPED] [NAMECONNECT] [STRSKILLS] skills [PROGRESSDESC]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "I have been impressed with how [NAME] has [DEVELOPED] [NAMECONNECT] skills over the course of the [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "I have been impressed with how [NAME] has [DEVELOPED] over the course of the [TIME]."
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2]
                    },
                    {
                        Progress: [3,4,5],
                        Attainment: [3,4,5]
                        }],
        Text:  "[NAMECONNECT] understanding of [STRTOPICS] have seen [PROGRESS] development."
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2]
                    }],
        Text:  "[NAMECONNECT] understanding of [WKTOPICS] have seen [PROGRESS] development."
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [4,5],
                    Effort: [4,5]
                    }],
        Text:  "[NAME] is very quick to pick up new concepts and when challenged by a task [NAME] is resilient and determined to succeed."
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] is quick to picks up new concepts and develop an understanding of [SUBJECT] topics."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has struggled [DEVELOPING] [NAMECONNECT] understanding."
    },

    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [4,5],
                    }],
        Text:  "The independence and speed at which [NAME] picked [STRSKILLS] was impressive."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [4,5],
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] has progressed [PROGRESSDESC]"
    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has [DEVELOPED] both [NAMECONNECT] subject understanding and skills." 

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] [STRSKILLS] skills have [DEVELOPED] [PROGRESSDESC]."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has shown [PROGRESS] progress whilst learning about [STRTOPICS]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] [STRSKILLS] skills have shown [PROGRESS] progress."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [2],
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] is making gradual, albeit slow, steps to improve [NAMECONNECT] [WKSKILLS] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Effort: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] has overcome various challenges when learning about [STRTOPICS] and [NAME] should be proud of this."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Effort: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAME] has overcome various challenges [DEVELOPING] [NAMECONNECT] [STRSKILLS] skills and [NAME] should be proud of this."

    },
    {
        ReportType: ['Art','Technology'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Effort: [3,4,5],
                    Creativity: [3,4,5]
                    }],
        Text:  "[NAME] has [DEVELOPED] [NAMECONNECT] ability to produce high quality and creative [OUTPUTONLY]."

    },
    {
        ReportType: ['Art','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Creativity: [3,4,5],
                    Attainment: [3,4,5]
                    }],
        Text:  "[NAMEACTUAL]'s confidence and creativity this [TIME] has stepped up and is clearly visible in [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }
                ],
        Text:  "[NAME] has made [PROGRESS] progress while tackling various [SUBJECT] assignments."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2],
                    Attainment: [1,2],
                    },
                ],
        Text:  "[NAME] still has work to do to improve [NAMECONNECT] [LEARNCONTEXT], especially when applying it to class assignments."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [4,5],
                    Effort: [4,5],
                    }],
        Text:  "[NAME] has continually been self-assessing and [DEVELOPING] [NAMECONNECT] understanding and skills.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2],
                    },
                    {
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has made [PROGRESS] [DEVELOPMENT] in [NAMECONNECT] understanding of [STRTOPICS].",

    },

    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [1,2],
                    Attainment: [1,2],
                    },
                    {
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has made [PROGRESS] [DEVELOPMENT] when applying [NAMECONNECT] [STRSKILLS] skills.",

    },

    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has learned a great deal with [NAMECONNECT] [STRSKILLS] showing [PROGRESS] [DEVELOPMENT].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAME] has only displayed limited [DEVELOPMENT].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAME] has made slow progress in [DEVELOPING] [NAMECONNECT] [WKSKILLS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAME] has made slow progress in [DEVELOPING] [NAMECONNECT] understanding of [WKTOPICS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1],
                    Attainment: [1,2],
                    Effort: [1,2],
                    }],
        Text:  "[NAME] has made no progress this [TIME], [NAME] must try harder and improve [NAMECONNECT] work ethic.",

    },
    {
        ReportType: ['Exam'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] is making [PROGRESS] progress on [NAMECONNECT] responses to complex exam questions.",

    },
    {
        ReportType: ['Exam'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] is also developing very effective exam technique in response to complex questions.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
                    {
                    Progress: [4,5],
                    }],
        Text:  "The progress [NAME] has made has been outstanding.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [ {
                    Progress: [4,5]
                    }],
        Text:  "The progress [NAME] made while working on [NAMECONNECT] [PROJECT] project has been outstanding.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2,3,4,5],
                    }],
        Text:  "[NAME] has made [PROGRESS] progress on [NAMECONNECT] [PROJECT] project.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] is making [PROGRESS] progress with [STRTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Project'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Progress: [4,5],
                    },
                    {
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        }],
        Text: "[NAME] has performed [PROGRESSDESC].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Project'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
                    {
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        }],
        Text: "[NAME] has the potential to do much better in [SUBJECT].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Project'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                        Progress: [4,5],
                        }],
        Text: "[NAMECONNECT] [OUTPUTONLY] is much improved on last [TIME] and [NAME] should be proud of this improvement.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Project','Exam'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                        Progress: [4,5],
                        }],
        Text: "This has been an improved [TIME] for [NAME], and one [NAME] should be proud of.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Project','Exam'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
                    {
                        Attainment: [1,2],
                        Progress: [4,5],
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        }],
        Text: "[NAME] has taken a more mature attitude towards [NAMECONNECT] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Progress: [3,4,5],
                    }],
        Text:  "[NAME] has explored and developed a range of [STRSKILLS] skills during [OTHWORKTYPE].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Progress: [1,2],
                    }],
        Text:  "Despite [NAMECONNECT] good [OUTPUTONLY], [NAME] could have [DEVELOPED] further still.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Progress: [1,2],
                    }],
        Text:  "Despite [NAMECONNECT] impressive attainment, [NAME] could have strived for a better mastery of [WKSKILLS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Progress: [1,2],
                    }],
        Text:  "Despite [NAMECONNECT] impressive [OUTPUTONLY], [NAME] could have strived for a better understanding of [WKTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [2],
                        Engagement: [2,3],
                        Effort: [2]
                    }],
        Text:  "[NAME] had begun to show major signs of improvement over the [TIME], but this has slipped recently.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5],
                        Engagement: [2,3],
                        Effort: [2]
                    }],
        Text:  "I have been so impressed with [NAME] this [TIME] and the active steps [NAME] has taken to improve.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Progress: [3,4,5],
                    }],
        Text:  "[NAME] is becoming more and more fluent in [NAMECONNECT] [OUTPUTONLY].",

    },
    {
        ReportType: ['Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [3,4,5],
                    }],
        Text:  "[NAME] has made pleasing progress and has developed better [COMPLUS] while playing [SPORT].",

    },
    {
        ReportType: ['Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [3,4,5],
                    }],
        Text:  "[NAME] has developed [NAMECONNECT] [COMPLUS] while playing [SPORT].",

    },
    {
        ReportType: ['Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [1,2,3,4,5],
                    }],
        Text:  "[NAME] has been developing [NAMECONNECT] stamina, speed and strength in [SPORT].",

    },
    {
        ReportType: ['Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [1,2,3,4,5],
                    }],
        Text:  "[NAME] has been developing [NAMECONNECT] [COMPLUS] in [SPORT].",

    },
    {
        ReportType: ['Art','Technology','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5],
                        Confidence:[4,5]
                    }],
        Text: "It has been encouraging to see [NAMEREF] performing with greater confidence, which has had a positive impact on [NAMECONNECT] [OUTPUTONLY].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5],
                        Confidence:[4,5]
                    }],
        Text: "[NAMECONNECT] work has gone from strength to strength.",
    
    },


    


    
]

