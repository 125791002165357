
import React, { useState } from "react";
import "./App.css";
import "./Style/structural.css";
import "./Style/component.css";
import "./Style/typography.css";

import ReportForm from "./Container/ReportForm";

import Footer from "./Components/footer/footer";
import Banner from "./Components/Banner/Banner";
import Modal from "./Components/Modal/modal";

function App() {


  const [aboutToggle, setAboutToggle] = useState(false);

  const toggleModal = () => {
    window.scrollTo(0, 0);
    console.log(aboutToggle)
    setAboutToggle(()=>!aboutToggle)
  }






  return (
    <div className="App">
      
      <Banner/>
      
      
      <ReportForm />
      <Footer toggleModal={toggleModal} />
      

      <Modal clicked={toggleModal} show={aboutToggle}>
        <div className='sPad'>
        <h2 className='pColor'>Hello</h2>
        <div className='left-align'>
          <p>Report App was built to help teachers write reports.</p>
          <p>Report writing is often a template first process.  A teacher will take a historical report and tailor it to a specific student.  A fairly laborious and time consuming process.</p>
          <p>This app flips this and introduces a student first process. Where the teacher considers the student and generates a report template based on the student.</p>
          <p>From initial trials, this reduces the time it takes to produce reports by at least 50% and most importantly improves the quality of the reports.</p>
          <p>The tool is still being developed, so please provide feedback so I can continually improve it.</p>
          <p>Lastly, if you found the tool useful, please consider buying me a coffee (or beer). While the tool is free to use, it is not free to run.</p>
          <p>Enjoy</p>
          <p className='bold'>Nick Green</p>

        </div>
        

        

        </div>
        
      </Modal>
    </div>
  );
}

export default App;
