import React from "react";
import "./textInput.css";

const TextInput = ({ question, withInputChange }) => {
  return (
    <div className="inputWrap">
      <label className="fw5">{question.label}</label>
      <input
        type="text"
        id={question.id}
        value={question.value}
        onChange={(e) => withInputChange(e.target.value, e.target.id)}
      ></input>
    </div>
  );
};

export default TextInput;
