export const pupilData = [
  {
    id: 1,
    title: "Firstname",
    label: "Firstname",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "text",
    value: "",
    options: [],
    help: {
      intro: "What is the students first name? ",
      examples: ["John","Susan"],
      context: ["This personalises the report with the student's name"],
      other: ["This field must be populated."]
    }
  },
  {
    id: 2,
    title: "Sex",
    label: "Sex",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Select",
    value: "Female",
    options: ["Female", "Male"],
    help: {
      intro: "What is the students sex?",
      examples: ["Male", "Female"],
      context: ["This populates the report with sex based promouns and adjectives; He, She, Himself, Herself etc"],
      other: []
    }
  },
  {
    id: 3,
    title: "Attainment",
    label: "Attainment",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: ["Needs Work", "Ok", "Good", "Excellent"],
    labels: ["Poor", "Solid", "Excellent"],
    help: {
      intro: "What was the quality of the work produced?",
      examples: ["Poor", "Below Average", "Solid","Good", "Excellent"],
      context: ["She had an excellent term.", "His work showed promise."],
      other: []
    },
  },  
  {
    id: 4,
    title: "Effort",
    label: "Resilience",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: ["Needs Work", "Ok", "Good", "Excellent"],
    labels: ["Absent", "Some", "Industrious"],
    help: {
      intro: "How much effort did the student apply during the period?",
      examples: ["Absent", "Little", "Some","Plenty", "Lots"],
      context: ["He applies himself fully in lessons.","She works very hard"],
      other: []
    }
  },
  {
    id: 5,
    title: "Progress",
    label: "Progress",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: ["Needs Work", "Ok", "Good", "Excellent"],
    labels: ["None", "Some", "Lots"],
    help: {
      intro: "How much progress did the student make over the review period?",
      examples: ["None", "Little", "Some", "Plenty", "Lots"],
      context: ["He made excellent progress this term.", "She failed develop her skills significantly."],
      other: []
    }
  },
  {
    id: 6,
    title: "Engagement",
    label: "Engagement",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: ["Dispruptive", "Quiet", "Engaged", "Delightfully Enthusiastic"],
    labels: ["Disruptive", "Quiet", "Enthusiastic"],
    help: {
      intro: "How engaged/interested was the student in the class and how involved were they in class discussions?",
      examples: ["Disruptive", "Disinterested","Quiet","Engaged","Enthusiastic"],
      context: ["He made insightful thoughts during class discussions.", "She tackles new challenges with enthusiasm"],
      other: []
    }
  },
  {
    id: 7,
    title: "Confidence",
    label: "Confidence",
    condition: ['General','Sport','Art','Drama','Technology','Science','Exam','Language'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Unsure", "", "Confident"],
    help: {
      intro: "How confident is the student applying his skill and knowledge independantly?",
      examples: ["Low Confidence/High Attainment - Will focus the report on building students confidence", "High Confidence/Low Attainment - Will encourage student to focus on detail and to seek more assistance."],
      context: [],
      other: []
    }
  },
  {
    id: 8,
    title: "Creativity",
    label: 'Creativity',
    condition: ['Drama','Art','Technology','Project'],
    focus: ['General','Exam','Project'],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Uninventive", "Some", "Inventive"],
    help: {
      intro: "How creatively does the student apply his knowledge and skills?",
      examples: [],
      context: ["She picked up the concepts quickly", "He was a little slow to take to algebra"],
      other: []
    }
  },
  {
    id: 9,
    title: "Exam",
    label: "Exam Technique",
    condition: ['General','Art','Drama','Technology','Science','Exam','Language'],
    focus: ['Exam'],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Poor", "Ok", "Excellent"],
    help: {
      intro: "How strong is the students exam technique?",
      examples: [],
      context: ["He has ______ exam technique.", "Her recent test recent showed _____ exam readiness."],
      other: []
    }
  },
  {
    id: 10,
    title: "ProjectProgress",
    label: "Project Progress",
    condition: ['General','Sport','Art','Drama','Technology','Science','Exam','Language'],
    focus: ['Project'],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Very Behind", "On Schedule", "Very Ahead"],
    help: {
      intro: "Is the student on schedule with the delivery of the project?",
      examples: [''],
      context: ["Sue is progressing her project on schedule", "Her project is nearing completion and I do not foresee any issue with her meeting the deadline."],
      other: []
    }
  },  
  {
    id: 11,
    title: "CommonDev",
    label: "Key Development Areas",
    condition: ['General','Art','Drama','Technology','Science','Language','Sport'],
    focus: ['General','Exam','Project'],
    type: "Checked",
    value: [
      {label: "Attention to detail", check: false, value: "detail"},
    {label: "Depth of understanding", check: false, value: "depth"},
    {label: "Consistency of work", check: false, value: "consistency"},
    {label: "Structure of work", check: false, value: "structure"},
    {label: "Accuracy of work", check: false, value: "accuracy"},
    {label: "Quality of work", check: false, value: "quality"}
  ],
    options: [],
    help: {
      intro: "What does the student need to improve specifically within their work?",
      examples: [],
      context: ["Next term, John will need to improve his works _____", "Her work showed excellent _____.",],
      other: ["Leave blank if no areas of improvement"]
    }
  },
  {
    id: 12,
    title: "KnowledgeDev",
    label: "Knowledge Development Areas",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Checked",
    value: [],
    options: [],
    help: {
      intro: "What specific elements of knowledge does the student need to improve?",
      examples: [],
      context: [ 
      "To cement her skills, I recommend she reads up on _____.",
      "Next term, John will develop his understanding of _____ to progress further.",
      "His understanding of _____ is still shallow at this stage",
      "John has understood _____ but is still struggling with _____.",
    ],
      other: ["Leave blank if no areas of improvement"]
    }
  },
  {
    id: 13,
    title: "SkillDev",
    label: "Skill Development Areas",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Checked",
    value: [],
    options: [],
    help: {
      intro: "What does skills does the student need to actively practice and enhance?",
      examples: [],
      context: ["Next term, John will need to work on his _____ to progress further.", "To cement her skills, I recommend she continues to work on her _____.",],
      other: ["Leave blank if no areas of improvement"]
    }
  },
  {
    id: 14,
    title: "ExamDev",
    label: "Exam Technique Development Areas",
    condition: ['General','Art','Drama','Technology','Science','Language'],
    focus: ['Exam'],
    type: "Checked",
    value: [
    {label: "Answering the question", check: false, value: "question"},
    {label: "Detailing process", check: false, value: "process"},
    {label: "Time management", check: false, value: "time"},
    {label: "Using resources", check: false, value: "resources"},
    {label: "Using examples", check: false, value: "examples"},
    {label: "Depth of answer", check: false, value: "consolidation"}
  ],
    options: [],
    help: {
      intro: "What elements of exams preparation does the student need to work on?",
      examples: [],
      context: ["He should now focus on ______ to improve his exam technique.", "Her recent test recent indicate a need to work on _____."],
      other: ["Leave blank if no areas of improvement"]
    }
  },
]
