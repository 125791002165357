

const _pipe = (a, b) => (arg) => b(a(arg));


export const pipe = (...ops) => ops.reduce(_pipe)


const getRandomNum = (min, max) => Math.floor(Math.random() * (max - min) + min)

export const selectRandom = (array) => {
    return  array[getRandomNum(0,array.length)]
}


export const buildNameObj = (name, sex)=>{

    let nameObj = {}
    nameObj.name = name
    nameObj.nameConnect = (sex === "Male" ? "his" : "her")
    nameObj.nameAlt = (sex === "Male" ? "he" : "she")
    nameObj.nameSelf = (sex === "Male" ? "himself" : "herself")
    nameObj.nameRef = (sex === "Male" ? "him" : "her")
    return nameObj
}

export const buildStringFromArray = (topicArray, defaultArr) => {

    let tempArr = []

    if (topicArray.length === 0 || topicArray.length > 3){
        tempArr = defaultArr
    }else{
        tempArr = topicArray
    }

    if (tempArr.length === 1){
        return tempArr[0]
    }

    return tempArr.reduce((prev, val, i)=>{

            if (i===0){
                return prev + val
            }

            if (i===(tempArr.length -1)){
                return prev + " and " + val
            }
            return prev + ", " + val
    },"")

}

const buildExamTech = (checkArr,Vocab) => {

    let tempTech = checkArr.reduce((combined,check,i)=>{


        if(check.check){

            return [...combined,selectRandom(Vocab.technique[check.value])]
        }

        return combined

    },[])

    return buildStringFromArray(tempTech, ["practicing exam style questions"])

}

export const createCheckedArr = (checkArr, bool) => {


    let filtered = checkArr.filter((option) => option.check === bool).map((option)=>{

            return option.value

        
    })

    return filtered


}



export const determinReportOrder = (studentObj, focus = 'General') => {


    let arr = []


    if (focus === 'General'){

        arr = [
            ['Progress',studentObj['Progress']],
            ['Attainment',studentObj['Attainment']],
            ['Effort',studentObj['Effort']],
            ['Engagement',studentObj['Engagement']], 
             
        ]

    }else if (focus === 'Assignment'){


    } else {

        arr = [
            ['Progress',studentObj['Progress']],
            ['Attainment',studentObj['Attainment']],
            ['Effort',studentObj['Effort']],
            ['Engagement',studentObj['Engagement']],  
        ]

    }



    if(arr[0][1]<3 && arr[1][1]<3 && arr[3][1]<4 && arr[2][1]<3){

        let tails = pipe(returnNames,shuffle)(arr.slice(2))
        let intros = pipe(returnNames,shuffle)(arr.slice(0,2))

        if (focus === 'Exam') {
            return [...intros, ...tails,"Exam","Steps"]
        }

        return [...intros, ...tails,"Steps"]
        
    }



    let sorted = sortArr(arr)

    let top = pipe(getTop,returnNames,shuffle)(sorted)

    let other = pipe(getOther,returnNames)(sorted)

    if (focus === 'Exam') {
        return [...top,...other,"Exam","Steps"]
    }

    return [...top,...other,"Steps"]

}


export const defineCharacter = (confidence = 3,engagement = 4,effort= 3) => {


    if (confidence>3 && engagement > 3 && effort > 3){

        return "engaged, industrious and confident"


    }else if (confidence>3 && effort > 3 && engagement === 3){

        return "industrious, confident and considerate"

    }else if (engagement>3 && effort > 3 && confidence < 4){

        return "engaged, industrious and conscientious"


    }else if(effort>2 && engagement === 3 && confidence<4){

        return "diligent, considerate and conscientious"

    }else if(effort>2 && confidence < 4 && engagement < 3){

        return "conscientious and social"

    }else if(effort>2 && confidence > 2 && engagement > 2){

        return "resolute and engaged"
    
    
    }else if(effort<3 && confidence < 4 && engagement < 3){

        return "free spirited and social"
    
    }else if(effort<3 && confidence < 4 && engagement === 3){

        return 'quiet and relaxed'

    }else if(engagement > 2){

        return 'engaged'

    }else if(effort > 2){

        return 'confident'

    }else if(confidence > 2){

        return 'industrious'

    } else {
        return "interesting"
    }


}


const checkPatterns = (patternsArr,studentObj) => {

    


        return patternsArr.some((patternObj) => {

            let keys = Object.keys(patternObj)
            let check = keys.reduce((state, key)=>{
                
                    if (state === false) return false

                    return inArray(+studentObj[key],patternObj[key])
            },true)

            return check

        })

}



export const validateSentence = (sentenceObj, studentObj, classObj, state) => {

    //if sentence not applicable to report type return no

    

    if (sentenceObj.ReportType.indexOf(classObj.ReportType) === -1){

        return false                        
    }

    if (sentenceObj.Context.indexOf(classObj.Focus) === -1){

        return false                        
    }

    // check sentence is right type

    if (sentenceObj.Position !== "All"){

                    

        if (state.Intro === true  && sentenceObj.Position === 'Filler'){
            
            return false
        }

        if (state.Intro === false  && sentenceObj.Position === 'Intro'){
            
            return false
        }

    }


    


        if (state.Opinion === true  && sentenceObj.Opinion=== true){
                
            return false
        }
    

        if (state.Strtopics === true  && /STRTOPICS/g.test(sentenceObj.Text)){
            
            return false
        }

        if (state.Wktopics === true  && /WKTOPICS/g.test(sentenceObj.Text)){
            
            return false
        }

        if (state.Strskills === true  && /STRSKILLS/g.test(sentenceObj.Text)){
            
            return false
        }

        if (state.Wkskills === true  && /WKSKILLS/g.test(sentenceObj.Text)){
            
            return false
        }

        if (state.Comfeed === true  && /COMFEED/g.test(sentenceObj.Text)){

            
            return false
        }









    // check patterns

    return checkPatterns(sentenceObj.Patterns,studentObj)




}


export const sortArr = (arr) => {

    return arr.sort((a, b) => {return b[1]-a[1];})

}

export const getTop = (arr) => {

    let top = arr[0][1]
    return arr.filter(a => a[1]===top)

}

export const getOther = (arr) => {

    let top = arr[0][1]
    return arr.filter(a => top>a[1])

}

export const returnNames = (arr) => {

    return arr.map(a => a[0])

}

export const inArray = (check,arr) => {

    return arr.indexOf(check) > -1

}

export const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
    }

    return array;
}


export const convertAtoAn = (string) =>{

    let anWord = ['excellent', 'impressive', 'imperfect',"outstanding","exceptional",'inquisitive','engaged',"active",'uninterested',"unengaged","industrious","active",'independent','enthusiastic','engaging']


    return anWord.reduce((nString,word)=>{


        let lookStr = "a "+ word
        let replaceStr = "an "+ word

        return nString.replace(lookStr,replaceStr)


    },string)



}

export const removeDuplicates = (string) =>{

    let anWord = ['topics', 'skills','project']


    return anWord.reduce((nString,word)=>{


        let lookStr = word +" "+ word
        let replaceStr = word

        return nString.replace(lookStr,replaceStr)


    },string)

    
}

export const buildKeyWordObj = (classObj,studentObj,Vocab) =>{

    let keyWordObj ={}

    keyWordObj["strskills"] = buildStringFromArray(createCheckedArr(studentObj.SkillDev, false), ["various skills"]).toLowerCase()
    keyWordObj["wkskills"] = buildStringFromArray(createCheckedArr(studentObj.SkillDev, true), ["various skills"]).toLowerCase()
    keyWordObj["attworktype"] = selectRandom(Vocab.worktype[classObj.ReportType.toLowerCase()])
    keyWordObj["othworktype"] = selectRandom(Vocab.worktype[classObj.ReportType.toLowerCase()])
    keyWordObj["outputonly"] = selectRandom(Vocab.outputonly[classObj.ReportType.toLowerCase()])
    keyWordObj["location"] = selectRandom(Vocab.location[classObj.ReportType.toLowerCase()])
    keyWordObj["outputhas"] = selectRandom(Vocab.outputhas[classObj.ReportType.toLowerCase()])
    keyWordObj["outputis"] = selectRandom(Vocab.outputis[classObj.ReportType.toLowerCase()])
    keyWordObj["objectdefault"] = selectRandom(Vocab.objectdefault[classObj.ReportType.toLowerCase()])
    keyWordObj["strtopics"] = buildStringFromArray(createCheckedArr(studentObj.KnowledgeDev, false), [Vocab.topicdefault[classObj.ReportType.toLowerCase()]])
    keyWordObj["wktopics"] = buildStringFromArray(createCheckedArr(studentObj.KnowledgeDev, true), [Vocab.topicdefault[classObj.ReportType.toLowerCase()]])
    keyWordObj["sports"] = buildStringFromArray(classObj.Sports, ["sports"])
    keyWordObj["projects"] = buildStringFromArray(classObj.Projects, ['project'])
    keyWordObj["comfeed"] = buildStringFromArray(createCheckedArr(studentObj.CommonDev, true), [selectRandom(Vocab.objectdefault[classObj.ReportType.toLowerCase()])]).toLowerCase()
    keyWordObj["complus"] = buildStringFromArray(createCheckedArr(studentObj.CommonDev, false), [selectRandom(Vocab.objectdefault[classObj.ReportType.toLowerCase()])]).toLowerCase()
    keyWordObj["attainment"] = selectRandom(Vocab.attainment[+studentObj.Attainment-1])
    keyWordObj["progress"] = selectRandom(Vocab.progress[+studentObj.Progress-1])
    keyWordObj["effort"] = selectRandom(Vocab.effort[+studentObj.Effort-1])
    keyWordObj["approach"] = selectRandom(Vocab.approach[+studentObj.Engagement-1])
    keyWordObj["workswith"] = selectRandom(Vocab.workswith[+studentObj.Effort-1])
    keyWordObj["workethic"] = selectRandom(Vocab.workethic[+studentObj.Effort-1])
    keyWordObj["attainmentdesc"] = selectRandom(Vocab.attainmentdesc[+studentObj.Attainment-1])
    keyWordObj["struggle"] = selectRandom(Vocab.struggle[+studentObj.Attainment-1])
    keyWordObj["displayed"] = selectRandom(Vocab.displayed[+studentObj.Attainment-1])
    keyWordObj["character"] = defineCharacter(+studentObj.Confidence,+studentObj.Engagement,+studentObj.Effort)
    keyWordObj["engage"] = selectRandom(Vocab.engage[+studentObj.Engagement-1])
    keyWordObj["progressdesc"] = selectRandom(Vocab.progressDesc[+studentObj.Progress-1])
    keyWordObj["progressed"] = selectRandom(Vocab.progressed[+studentObj.Progress-1])
    keyWordObj["learncontext"] = selectRandom(Vocab.learncontext[classObj.ReportType.toLowerCase()])
    keyWordObj["developing"] = selectRandom(Vocab.developing)
    keyWordObj["developed"] = selectRandom(Vocab.developed)
    keyWordObj["develop"] = selectRandom(Vocab.develop)
    keyWordObj["development"] = selectRandom(Vocab.development)
    keyWordObj["impression"] = selectRandom(Vocab.impression[+studentObj.Effort-1])
    keyWordObj["obtained"] = selectRandom(Vocab.obtained[+studentObj.Attainment-1])
    keyWordObj["capacity"] = selectRandom(Vocab.capacity)
    keyWordObj["mastery"] = selectRandom(Vocab.mastery[+studentObj.Attainment-1])
    keyWordObj["name"] = (studentObj.Firstname === "") ? "the student" : studentObj.Firstname 
    keyWordObj["subject"] = (classObj.Subject === "") ? "the subject" : classObj.Subject
    keyWordObj["introtext"] = selectRandom(Vocab.introtext)
    keyWordObj["exam"] = selectRandom(Vocab.exam[+studentObj.Exam-1])
    keyWordObj["technique"] = buildExamTech(studentObj.ExamDev,Vocab)


    return keyWordObj


}

const checkCheckBox = (checkArr) => {

    return checkArr.some((element) => element.check)
    
}


export const buildReportStateObj = (reportType,focus,topicCheck,skillCheck,commonCheck,intro=true) =>{

    let reportState = {}

    reportState["Type"] = reportType 
    reportState['Focus'] =  focus
    reportState['Intro'] = intro
    reportState['Link'] = false
    reportState['Connect'] = false
    reportState['Opinion'] = false
    reportState['Alltopics'] = false
    reportState['Allskills'] = false
    reportState['Wktopics'] = (checkCheckBox(topicCheck)) ? false : true
    reportState['Wkskills'] = (checkCheckBox(skillCheck)) ? false : true
    //reportState['Comfeed'] = false
    reportState['Comfeed'] = (checkCheckBox(commonCheck)) ? false : true
    return reportState


}


export const convertString = (stringtoConvert,nameObj,keyWordObj,studentObj,classObj) => {




    const titleCase = (string) => {

        let sentenceArray = string.split("  ").filter((e) => e.length>1).map((sentence,i) => {
            
            return sentence[0].toUpperCase() + sentence.slice(1);

        })
        return sentenceArray.join("  ");

     }


    let wordReplaceString = stringtoConvert
    .replace(/\[INTROTEXT\]/g,keyWordObj.introtext)
    .replace(/\[NAMEREF\]/g,nameObj.nameRef)
    .replace(/\[NAMESELF\]/g,nameObj.nameSelf)
    .replace(/\[OTHWORKTYPE\]/g,keyWordObj["othworktype"])
    .replace(/\[ATTWORKTYPE\]/g,keyWordObj["attworktype"])
    .replace(/\[OUTPUTHAS\]/g,keyWordObj["outputhas"])
    .replace(/\[OUTPUTONLY\]/g,keyWordObj["outputonly"])
    .replace(/\[OUTPUTIS\]/g,keyWordObj["outputis"])
    .replace(/\[OBJECTDEFAULT\]/g,keyWordObj["objectdefault"])
    .replace(/\[LOCATION\]/g,keyWordObj.location)
    .replace(/\[STRTOPICS\]/g,keyWordObj["strtopics"])
    .replace(/\[WKTOPICS\]/g,keyWordObj["wktopics"])
    .replace(/\[STRSKILLS\]/g,keyWordObj["strskills"])
    .replace(/\[WKSKILLS\]/g,keyWordObj["wkskills"])
    .replace(/\[PROJECTS\]/g,keyWordObj["projects"])
    .replace(/\[COMPLUS\]/g,keyWordObj.complus)
    .replace(/\[COMFEED\]/g,keyWordObj.comfeed)
    .replace(/\[ABILITY\]/g,keyWordObj.attainment)
    .replace(/\[PROGRESS\]/g,keyWordObj.progress)
    .replace(/\[APPROACH\]/g,keyWordObj.approach)
    .replace(/\[ABILITYDESC\]/g,keyWordObj.attainmentdesc)
    .replace(/\[STRUGGLE\]/g,keyWordObj.struggle)
    .replace(/\[EFFORT\]/g,keyWordObj.effort)
    .replace(/\[DISPLAYED\]/g,keyWordObj.displayed)
    .replace(/\[WORKSWITH\]/g,keyWordObj.workswith)
    .replace(/\[WORKETHIC\]/g,keyWordObj.workethic)
    .replace(/\[PROJECT\]/g,keyWordObj.projects)
    .replace(/\[CHARACTER\]/g,keyWordObj.character)
    .replace(/\[SPORT\]/g,keyWordObj.sports)
    
    .replace(/\[ENGAGE\]/g,keyWordObj.engage)
    .replace(/\[PROGRESSDESC\]/g,keyWordObj.progressdesc)
    .replace(/\[LEARNCONTEXT\]/g,keyWordObj.learncontext)
    .replace(/\[IMPRESSION\]/g,keyWordObj.impression)
    .replace(/\[DEVELOPING\]/g,keyWordObj.developing)
    .replace(/\[DEVELOP\]/g,keyWordObj.develop)
    .replace(/\[DEVELOPED\]/g,keyWordObj.developed)
    .replace(/\[DEVELOPMENT\]/g,keyWordObj.development)
    .replace(/\[OBTAINED\]/g,keyWordObj.obtained)
    .replace(/\[PROGRESSED\]/g,keyWordObj.progressed )
    .replace(/\[CAPACITY\]/g,keyWordObj.capacity)
    .replace(/\[MASTERY\]/g,keyWordObj.mastery)
    .replace(/\[TECHNIQUE\]/g,keyWordObj.technique)
    .replace(/\[EXAM\]/g,keyWordObj.exam)
    .replace(/\[TIME\]/g,classObj.Timeframe.toLowerCase())
    .replace("[NAME]",keyWordObj["name"])
    .replace(/\[NAMEACTUAL\]/g,keyWordObj["name"])
    .replace(/\[NAME\]/g,nameObj.nameAlt)
    .replace(/\[SUBJECT\]/g,keyWordObj["subject"])
    .replace(/\[NAMECONNECT\]/g,nameObj.nameConnect)

     return titleCase(convertAtoAn(removeDuplicates(wordReplaceString)))


}