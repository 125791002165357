
export const Attainment= [
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [1,2,3],
                    Attainment: [1,2],
                    Engagement: [1,2,3],
                    }],
        Text: "[NAMECONNECT] work is often lacking in [COMFEED], something that [NAME] has the [CAPACITY] to change.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [1,2,3],
                    Attainment: [1,2],
                    Engagement: [1,2,3],
                    }],
        Text: "[NAMECONNECT] [OUTPUTIS] often lacking in [COMFEED], but [NAME] certainly has the [CAPACITY] to do better.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "[NAME]'s [TIME] has not been the easiest, [NAME] has struggled on a number of fronts, with [NAMECONNECT] work often lacking [COMFEED]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5]
                    }],
        Text: "[NAME] has produced [ABILITY] work demonstrating [COMPLUS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    Effort: [1,2,3]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME] in [SUBJECT] with [NAMECONNECT] work showing [COMPLUS].",

    },
    {
        ReportType: ['General','Science','Language','Art','Drama','Technology','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] been [ABILITY], demonstrating [NAMECONNECT] grasp of [STRTOPICS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has frequently demonstrated [NAMECONNECT] [ABILITY] aptitude with [STRSKILLS] skills.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has frequently demonstrated [NAMECONNECT] [ABILITY] knowledge of [STRTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Creativity: [3,4,5]
                    }],
        Text: "[NAME] has shown a real flair for [SUBJECT] this [TIME], [NAMECONNECT] work has been [ABILITY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Creativity: [3,4,5],
                    Progress: [3,4,5],
                    Engagement: [3,4,5]
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has a real flair for [SUBJECT].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has completed all [ATTWORKTYPE] to a [ABILITY] standard.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAME] has produced some [ABILITY] [OUTPUTONLY] in response to [ATTWORKTYPE]. An excellent [TIME], well done.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has demonstrated understanding and skill.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has demonstrated [NAMECONNECT] [ABILITY] ability to work well across disciplines."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has [DISPLAYED] [NAMECONNECT] [OBJECTDEFAULT] in various [OUTPUTONLY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Effort: [4,5],
                    Engagement: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text: "I have been [IMPRESSION] by nearly all aspects of [NAME]'s [OUTPUTONLY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Progress: [4,5],
                    }],
        Text: "[NAME] has performed [PROGRESSDESC].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] shows [ABILITY] ability when using [NAMECONNECT] [STRSKILLS] skills.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "[NAME] is still learning [WKTOPICS] at this stage, with [NAMECONNECT] [OUTPUTONLY] missing [COMFEED].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] understanding of [STRTOPICS] is [ABILITY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                     Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] application and understanding of [STRSKILLS] skills is [ABILITY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                     Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] use and understanding of [STRSKILLS] skills is [ABILITY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2,3],
                    Progress: [1,2,3]
                    },
                    {
                    Attainment: [3,4,5],
                    Progress: [3,4,5]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME] about [STRTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2,3],
                    Progress: [1,2,3]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME] whilst learning [WKTOPICS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Attainment: [3,4,5],
                    Progress: [3,4,5]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME] whilst developing [NAMECONNECT] [STRSKILLS] skills.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Progress: [1,2]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME] and struggled to develop [NAMECONNECT] [WKSKILLS] skills to the level [NAME] is capable of.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3],
                    Progress: [1,2,3]
                    }],
        Text: "[NAME] has had a [ABILITY] [TIME], but [NAMECONNECT] [WKSKILLS] skills still have plenty of scope for improvement.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] lacked the [COMFEED] required to reach the highest level.",
        
    },
    
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has clearly [OBTAINED] elements of the [STRSKILLS] skills applied in [LOCATION]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "While [NAME] has [OBTAINED] some elements of the syllabus, [NAMECONNECT] understanding is still light at this stage."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "While [NAME] has developed some [STRSKILLS] skills, applying these skillfully and consistently remains a challenge."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
            Attainment: [3,4,5],
            Progress: [3,4,5],
            }],
        Text: "[NAME] has [OBTAINED] and applied [NAMECONNECT] understanding of [STRTOPICS] [PROGRESSDESC]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text: "[NAME] has [OBTAINED] and applied [NAMECONNECT] [STRSKILLS] skills in [LOCATION] [PROGRESSDESC]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [2,3],
                    Progress: [2,3],
                    }],
        Text: "[NAME] has [OBTAINED] and applied [NAMECONNECT] [STRSKILLS] skills in [LOCATION]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text: "[NAME] has learned about [STRTOPICS] and demonstrated that knowledge in [ATTWORKTYPE]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text: "[NAME] has [OBTAINED] and applied [STRSKILLS] skills in [LOCATION]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Progress: [1,2,3],
                    }],
        Text: "[NAME] has struggled to apply [NAMECONNECT] [WKSKILLS] skills within [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Progress: [1,2],
                    }],
        Text: "[NAME] has struggled to apply [NAMECONNECT] understanding of [WKTOPICS] within [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated [NAMECONNECT] [STRSKILLS] skills during exercises."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated [NAMECONNECT] competency in [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated [NAMECONNECT] understanding of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Engagement: [4,5],
                    }],
        Text: "[NAME] enjoys tackling new challenges in [SUBJECT] which has resulted in [NAMEREF] producing a variety of [ABILITY] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has developed a [ABILITY] understanding of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has shown a [ABILITY] understanding of [STRTOPICS] within [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] highlighted a [ABILITY] understanding of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] highlighted [NAMECONNECT] [ABILITY] understanding of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] shown some understanding of [STRTOPICS] but additional revision of [WKTOPICS] is recommended."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] shown a [ABILITY] aptitude for [STRSKILLS] skills."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] demonstrated [NAMECONNECT] skill when approaching [ATTWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] demonstrates [NAMECONNECT] [MASTERY] [SUBJECT] regularly during [ATTWORKTYPE]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] demonstrated [NAMECONNECT] [MASTERY] [STRSKILLS] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] demonstrates [NAMECONNECT] [MASTERY] [STRSKILLS] skills during [LOCATION]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Confidence: [3,4,5]
                    }],
        Text: "[NAME] has [DISPLAYED] confidence during aspects of the subject. In particular [NAME] has [STRUGGLE] [STRSKILLS] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                        Attainment: [1,2],
                        Confidence: [1,2]
                    }],
        Text: "[NAME] has [DISPLAYED] confidence during aspects of the subject. In particular [NAME] has [STRUGGLE] [WKSKILLS] skills."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
            Attainment: [3,4,5],
            Confidence: [3,4,5]
        },],
        Text: "[NAME] has [DISPLAYED] confidence during aspects of the subject. In particular when learning about [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
        {
            Attainment: [1,2,3],
            Confidence: [1,2]
        }],
        Text: "[NAME] has [DISPLAYED] confidence during aspects of the subject. In particular when learning about [WKTOPICS]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
            Attainment: [3,4,5],
            Confidence: [3,4,5]
        },
        {
            Attainment: [1,2,3],
            Confidence: [1,2,3]
        }],
        Text: "[NAME] has [DISPLAYED] confidence during aspects of the subject. In particular when using [NAMECONNECT] [STRSKILLS] skills."
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] often been disappointing, often lacking the [COMFEED] required to reach the highest level."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2,3],
                    }],
        Text: "[NAMECONNECT] [OUTPUTIS] often completed to a [ABILITY] standard that could be [DEVELOPED] by improving [NAMECONNECT] work's [COMFEED]."

    },
    {
        ReportType: ['General','Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Effort: [1,2],
                    }],
        Text: "[NAMECONNECT] [OUTPUTONLY] often lacks [COMFEED], so [NAME] will need to focus on this."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Effort: [1,2,3],
                    }],
        Text: "[NAMECONNECT] [OUTPUTHAS] not always been at the level [NAME] is capable of, often lacking [COMFEED]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Engagement: [3,4,5],
                    Creativity: [3,4,5],
                    }],
        Text: "[NAME] is a talented student who is on track to succeed at a high level."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Engagement: [4,5],
                    }],
        Text: "[NAME] has displayed a [ABILITY] understanding and aptitude for [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4],
                    }],
        Text: "[NAMECONNECT] understanding in lessons is [ABILITY] but at times [NAME] could improve the [COMFEED] of [NAMEREF] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3],
                    }],
        Text: "[NAMECONNECT] application of [NAMECONNECT] skills in lessons is [ABILITY] but at times [NAME] could improve the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    Effort: [3,4,5],
                    Engagement: [3,4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated a [ABILITY] grasp of [STRTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has learnt and applied important skills which I hope [NAME] can utilise in the future."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text: "[NAME] has developed a [ABILITY] understanding of different [STRSKILLS] techniques."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    }],
        Text: "To realise [NAMECONNECT] potential, [NAME] must work on [NAMECONNECT] final output which is often lacking in [COMFEED]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text: "[NAME] has had trouble following the steps required to answer [WKSKILLS] assignments."

    },
    {
        ReportType: ['Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text: "[NAME] understands the steps required to answer [STRSKILLS] assignments [PROGRESSDESC]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [2,3],
                        Progress: [4,5],
                    }],
        Text: "[NAME] still needs work on the [COMFEED] of [NAMECONNECT] [OUTPUTONLY], though I am encouraged [NAME] will do so based on progress so far."

    },
    {
        ReportType: ['Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Engagement: [2,3,4,5],
                    }],
        Text: "[NAME] experiences difficulty in applying [NAMECONNECT] [WKSKILLS] skills to solve [ATTWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text: "[NAME] has difficulty applying [NAMECONNECT] [LEARNCONTEXT] to [OUTPUTONLY] and [ATTWORKTYPE]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] produces [ABILITY] [OUTPUTONLY] when applying [NAMECONNECT] [STRSKILLS] skills in project-based learning activities."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Languages'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] produces [ABILITY] [OUTPUTONLY] when applying [NAMECONNECT] knowledge of [STRTOPICS] to [ATTWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text: "When applying [NAMECONNECT] knowledge of [WKTOPICS] to [ATTWORKTYPE], the [COMFEED] of [NAMECONNECT] [OUTPUTIS] not reflective of [NAMECONNECT] potential."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2,3,4,5],
                    }],
        Text: "[NAME] has [STRUGGLE] [STRSKILLS] skills."

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [1,2],
                    }],
        Text: "[NAME] is having trouble with retaining and applying [NAMECONNECT] knowledge of [WKTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [4,5],
                    }],
        Text: "While [NAMECONNECT] progress is encouraging, [NAME] must continue to develop the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [1,2,3],
                        Effort: [1,2],
                    }],
        Text: "[NAME] makes careless mistakes due to not revising work carefully."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] demonstrates a [ABILITY] grasp of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                        Attainment: [1],
                    }],
        Text: "[NAME] demonstrates a [ABILITY] grasp of [WKTOPICS]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] demonstrates a [ABILITY] grasp of [STRTOPICS] when applying [NAMECONNECT] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                    }],
        Text: "[NAMECONNECT] [STRSKILLS] skills have been impressive.  [NAME] should be proud of this."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] has shown a thorough understanding of [STRTOPICS] and uses constructive feedback well."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                    }],
        Text: "[NAME] has demonstrated thorough knowledge and understanding of [STRTOPICS] during [ATTWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] has shown a [ABILITY] understanding of the details and complexities of [STRTOPICS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4],
                        Effort: [1,2,3],
                    }],
        Text: "[NAME] has a [ABILITY] grasp of [STRTOPICS], however, [NAME] has a tendency to rush [NAMECONNECT] [ATTWORKTYPE], resulting in some lack of [COMFEED]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4],
                        Effort: [1,2,3],
                    }],
        Text: "While [NAMECONNECT] work is [ABILITY], I feel at times [NAME] could strive for greater results."


    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [3,4,5],
                        Creativity: [4,5],
                    }],
        Text: "[NAMECONNECT] [OUTPUTONLY] shows excellent creativity and innovative thinking."


    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Creativity: [4,5],
                    }],
        Text: "I have been impressed with the level of creativity within [NAMECONNECT] [OUTPUTONLY] which is always of an [ABILITY] standard."

    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4],
                        Effort: [3,4,5],
                        Creativity: [1,2,3],
                    }],
        Text: "[NAME] should look to explore ways to be more creative with [NAMECONNECT] [ABILITY] performances to take them to an even higher level."
    },
    {
        ReportType: ['Art','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3],
                        Creativity: [3,4,5],
                    }],
        Text: "Despite [NAMECONNECT] excellent creativity and talent, [NAMECONNECT] [OUTPUTIS] often limited in [COMFEED]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [4,5],
                    }],
        Text: "[NAME] has consistently produced a [ABILITY] standard of [OUTPUTONLY]."


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3],
                    }],
        Text: "[NAMECONNECT] [OUTPUTONLY], which is [ABILITY], could be improved further by developing [COMFEED]."


    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3],
                    }],
        Text: "[NAME] must consider how [NAME] could demonstrate [NAMECONNECT] understanding of [WKTOPICS] better, as at times, [NAMECONNECT] [OUTPUTONLY] lacks [COMFEED]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Develop: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text: "[NAME] must consider how [NAME] could demonstrate [NAMECONNECT] [WKSKILLS] skills with greater consistency, as at times [NAME] lacks focus."


    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] has shown [ABILITY] ability when applying [NAMECONNECT] [STRSKILLS] skills."


    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3],
                    }],
        Text: "[NAME] has demonstrated a [ABILITY] understanding of [WKSKILLS] skills and must work on [NAMECONNECT] [COMFEED] to develop them further."


    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] has demonstrated a [ABILITY] understanding of [STRSKILLS] skills and must push [NAMESELF] even more to improve the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [1,2,3,4],
                        Creativity: [4,5],
                    }],
        Text: "[NAME] can produce moments of brilliance but this can be overshadowed by lapses in concentration."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Creativity: [3,4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated [NAMECONNECT] creativity and ability with [STRSKILLS] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                    }],
        Text: "[NAME] is a [ABILITYDESC] student who has frequently demonstrated [NAMECONNECT] ability with [STRSKILLS] skills."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [1,2,3],
                        Engagement: [1,2],
                    }],
        Text: "When fully focused [NAME] is a [ABILITY] student."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                    }],
        Text: "[NAME] has excelled and improved with each [ATTWORKTYPE]. In particular, [NAME] should be very proud of [NAMECONNECT] [PROJECT] project."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                    }],
        Text: "Over the [TIME], I have been increasingly impressed with [NAMEACTUAL]'s abilities, demonstrated by the quality of [NAMECONNECT] [PROJECT] project."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4],
                        Effort: [2,3,4],
                    }],
        Text: "[NAMECONNECT] [OUTPUTIS] always of a [ABILITY] standard but at times, [NAME] could strive for a greater level of [COMFEED]."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                        Attainment: [3,4,5],
        
                    }],
        Text: "[NAME] has shown a [ABILITY] aptitude for [STRSKILLS] skills."

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAME] has demonstrated a [ABILITY] capability when using [NAMECONNECT] [STRSKILLS] skills."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Creativity: [3,4,5],
                    }],
        Text: "Some of [NAMECONNECT] insights have been impressive, a reflection of [NAMECONNECT] [ABILITY] ability in [SUBJECT]."

    },
    {
        ReportType: ['Art','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Creativity: [1,2,3,4],
                        Confidence: [1,2,3],
                    }],
        Text:     "I would like to see [NAMECONNECT] confidence in [NAMECONNECT] abilities grow so [NAME] can develop [NAMECONNECT] creativity and independence."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAMECONNECT] [OUTPUTIS] currently below [NAMECONNECT] potential, often lacking in [COMFEED]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] is having a difficult time understanding [WKTOPICS]."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [3,4,5],
                    }],
        Text:     "[NAME] is having a difficult time communicating what [NAME] has learnt in [NAMECONNECT] work."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [2,3],
                    }],
        Text:     "[NAME] would benefit from practicing [NAMECONNECT] [WKSKILLS] skills at home as the quality often lacks [COMFEED]."
    },
    {
        ReportType: ['Science','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] is struggling to retain an understanding of [WKTOPICS]."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Engagement: [4,5]
                    }],
        Text:     "[NAME] would benefit from slowing down and reviewing the [COMFEED] of [NAMECONNECT] work."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language','sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] has had trouble finishing [ATTWORKTYPE] to a satisfactory standard."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAMECONNECT] understanding of [WKTOPICS] still has some gaps and [NAME] could correct this by reading around the subject at home."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1],
                    }],
        Text:     "[NAMECONNECT] work is often incomplete and lacking in [COMFEED]."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1],
                        Effort: [1,2],
                        Engagement: [1,2],
                    }],
        Text:     "[NAME] should look to take more pride in [NAMECONNECT] [OUTPUTONLY], as it often lacks [COMFEED]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] is struggling with [WKSKILLS] skills and would benefit from self practice."
    },
    {
        ReportType: ['Science','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] is having a difficult time remembering information about [WKTOPICS] and applying it to [NAMECONNECT] work."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] would benefit from working on [NAMECONNECT] [WKSKILLS] skills."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                    }],
        Text:     "[NAME] would benefit from self study at home as [NAME] struggled to grasp some key concepts in [LOCATION]."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [1,2]
                    }],
        Text:     "[NAME] has found [SUBJECT] challenging."
    },
    {
        ReportType: ['Science','General','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Progress: [1,2]
                    }],
        Text:     "The [COMFEED] of [NAMECONNECT] [OUTPUTONLY] still requires significant improvement."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [2],
                        Engagement: [1,2],
                    }],
        Text:     "When motivated, [NAME] does well with [ATTWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1],
                    }],
        Text:     "[NAMECONNECT] frequent absences have undoubtedly impacted the development of [NAMECONNECT] [LEARNCONTEXT]."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has the [CAPACITY] to do well in [SUBJECT], certainly better than [NAMECONNECT] [OUTPUTONLY] this [TIME] suggests.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has the [CAPACITY] to do much better in [SUBJECT]. [NAME] will need to apply [NAMESELF] to do so.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [1,2],
                    }],
        Text:  "[NAME] will know that [ATTWORKTYPE] are not completed to the standard [NAME] is capable of.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] needs to strengthen [NAMECONNECT] understanding of [WKTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] needs to work on [DEVELOPING] [NAMECONNECT] understanding of [WKTOPICS] further as [NAMECONNECT] [OUTPUTONLY] often lacks [COMFEED].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] needs to improve [NAMECONNECT] [WKSKILLS] skills through self practice.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [1,2,3],
                    Engagement: [1,2,3]
                    }],
        Text:  "[NAMECONNECT] skill development is suffering due to [NAMECONNECT] lack of application in [LOCATION].",

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [1,2,3],
                    Engagement: [1,2,3]
                    }],
        Text:  "[NAMECONNECT] knowledge and understanding of [WKTOPICS] is suffering due to [NAMECONNECT] lack of application in [LOCATION].",

    },

    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has difficulty applying strategies in the construction of [NAMECONNECT] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has difficulty applying strategies in the construction of [NAMECONNECT] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] uses various strategies to produce [ABILITY] [OUTPUTONLY] when tackling [ATTWORKTYPE].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2,3,4],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has an understanding of [STRTOPICS] but often struggles with [WKTOPICS], something we will work on next [TIME].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Effort: [3,4,5],
                    }],
        Text:  "While [NAME] has an understanding of [WKTOPICS], applying [NAMECONNECT] knowledge is proving to be a challenge.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2,3],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] often struggles applying [NAMECONNECT] knowledge of [WKTOPICS] in [ATTWORKTYPE], something that continued practice will resolve.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] understands and applies [NAMECONNECT] [STRSKILLS] skills well in [ATTWORKTYPE].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [1,2],
                    }],
        Text:  "[NAME] is capable student but has worked below [NAMECONNECT] potential.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] seems to find [WKSKILLS] skills challenging and would benefit from working on this.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [1,2,3],
                    }],
        Text:  "[NAME] is capable of achieving a higher quality than [NAME] is currently displaying, with [NAMECONNECT] [OUTPUTONLY] often lacking [COMFEED].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] excels when applying [NAMECONNECT] [ABILITY] skills to [ATTWORKTYPE].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    Creativity: [4,5],
                    }],
        Text:  "[NAME] is a creative student and I have enjoyed seeing [NAMECONNECT] [ABILITY] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[WKTOPICS] [ATTWORKTYPE] have been very difficult for [NAMEACTUAL] this [TIME], and [NAME] would benefit from additional studying.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[WKSKILLS] skills have been very difficult for [NAMEACTUAL] this [TIME], and [NAME] would benefit from additional practice.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] uses higher-level skills to complete challenging [ATTWORKTYPE] to a consistently [ABILITY] standard.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] will need lots of repetition and practice to consolidate [NAMECONNECT] understanding of [WKTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has not delivered on [NAMECONNECT] potential.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has used [NAMECONNECT] understanding of [STRTOPICS] to produce [ABILITY] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has used [NAMECONNECT] [STRSKILLS] skills to produce [ABILITY] [OUTPUTONLY].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAMECONNECT] [WKSKILLS] skills are a little behind schedule and will need to be worked at.",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has struggled with [SUBJECT] this [TIME]. In particular, [NAMECONNECT] [WKSKILLS] skills are behind the level [NAME] is capable of.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] has struggled with [SUBJECT] this [TIME]. In particular, [NAMECONNECT] understanding of [WKTOPICS] is behind schedule.",

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] is having considerable difficulty retaining an understanding of [WKTOPICS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [1,2],
                    }],
        Text:  "[NAME] is having difficulty applying [NAMECONNECT] [WKSKILLS] skills.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] skill level is not reflective of [NAMECONNECT] efforts and further practice and repetition is required.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAME] has demonstrated [ABILITY] ability when working on the [PROJECT] project.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] has shown flair for [SUBJECT] when working on [NAMECONNECT] [PROJECT] project.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    }],
        Text:  "[NAME] has produced some [ABILITY] work, [NAMECONNECT] [PROJECT] project was most impressive.",

    },
    {
        ReportType: ['General','Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    Effort: [4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] responses to the various [ATTWORKTYPE].",

    },
    {
        ReportType: ['General','Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] responses to [ATTWORKTYPE] have shown a [ABILITY] understanding of [STRTOPICS].",

    },
    {
        ReportType: ['General','Science'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2],
                    }],
        Text:  "While some of [NAMECONNECT] responses to [ATTWORKTYPE] have shown a good awareness of [WKTOPICS], the [COMFEED] of the answers still need to be worked on.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4,5],
                    Effort: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has shown a [ABILITY] understanding of the work covered.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [2],
                    Effort: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has shown a some understanding of the work covered.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Effort: [3,4,5],
                    Progress: [4,5],
                    }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] [PROJECT] work",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2,3,4],
                    Creativity: [4,5],
                    }],
        Text:  "[NAME] has a strong creative flair and it would be nice to see [NAMEREF] display this more in [NAMECONNECT] [OUTPUTONLY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [3,4],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] produces [ABILITY] [OUTPUTONLY] but at times the lack of overall [COMFEED] stops [NAMEREF] from really excelling.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [2,3,4],
                    Effort: [1,2,3],
                    Creativity: [4,5],
                    }],
        Text:  "While [NAME] is capable of producing excellent [OUTPUTONLY], [NAME] needs to work the overall [COMFEED] of [NAMECONNECT] work.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [4,5],
                    Effort: [4,5],
                    Creativity: [3,4,5],
                    }],
        Text:  "I thought [NAMEACTUAL]'s [PROJECT] project was well researched and delivered.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                        Creativity: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] [PROJECT] project was well researched and delivered.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "I'm really keen for [NAME] to start fulfilling [NAMECONNECT] potential.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "I was really keen for [NAME] to deliver on [NAMECONNECT] potential this [TIME], however, [NAMECONNECT] [OUTPUTIS] still missing the [COMFEED] [NAME] is capable of.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "[NAME] has the [CAPACITY] to do very well in [SUBJECT].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "[NAME] will be the first to admit the [COMFEED] of [OUTPUTONLY] this [TIME] has not met [NAMECONNECT] [CAPACITY].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "[NAME] will be disappointed with [NAMECONNECT] development.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2,3],
                    }],
        Text:  "I'm confident that [NAME]'s poor attainment this [TIME] can be corrected.",
     },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Intro',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [1,2],
                            Progress: [1,2],
                            Engagement: [1,2,3],
                        }],
            Text:  "[NAME] has had a disappointing [TIME] in [SUBJECT] and has falled behind a little.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [1,2],
                            Progress: [1,2],
                            Engagement: [1,2,3],
                        }],
            Text:  "[NAME] is clearly capable of doing the [SUBJECT] work involved.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [1,2],
                            Progress: [1,2],
                            Engagement: [1,2,3],
                        }],
            Text:  "[NAMECONNECT] potential does not always present itself when it comes to [NAMECONNECT] [OUTPUTONLY], which can be sloppy.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [4,5],
                            Effort: [3,4,5],
                            Progress: [3,4,5],
                            Engagement: [4,5],
                        }],
            Text:  "[NAME] has come into [NAMECONNECT] own in our study of [STRTOPICS].",
    
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: true,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [4,5],
                            Effort: [3,4,5],
                            Progress: [3,4,5],
                            Engagement: [4,5],
                        }],
            Text:  "It has been great to see [NAMEREF] excel when [NAMECONNECT] [STRSKILLS] skills.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,
            Opinion: true,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [4,5],
                            Effort: [3,4,5],
                            Progress: [3,4,5],
                            Engagement: [4,5],
                        }],
            Text:  "It has been great to see [NAMEREF] excel when applying [NAMECONNECT] understanding of [STRTOPICS].",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [1,2,3],
                        }],
            Text:  "[NAME] occasionally rushes [NAMECONNECT] [OUTPUTONLY] and does not complete it with the expected level of [COMFEED].",
    
        },
        {
            ReportType: ['Art','Technology'],
            Position: 'Filler',
            Context: ['General','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text:  "Within [NAMECONNECT] project, [NAMECONNECT] [STRSKILLS] skills showed a good deal of talent and potential.",
    
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2,3],
                            Confidence: [1,2,3],
                        }],
            Text:  "[NAME] has found [WKSKILLS] skills a little more challenging so continued practice will be recommended.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [2],
                            Engagement: [2,3,4,5],
                        }],
            Text:  "While [NAME] can produce good [OUTPUTONLY], [NAME] is not always consistent in doing this.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [2,3,4],
                        }],
            Text:  "[NAME] is comfortable with most [STRTOPICS] topics covered, though [NAMECONNECT] understanding of [WKTOPICS] still requires consolidation.",
    
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [2,3],
                        }],
            Text:  "While [NAMECONNECT] theory of [STRTOPICS] is comfortable, [NAME] still needs practice applying this knowledge in exercises which occasionally lack [COMFEED].",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [4,5],
                        },
                        {
                            Attainment: [1,2],
                            Engagement: [4,5],
                        }],
            Text:  "[NAMECONNECT] work has highlighted a need to improve the [COMFEED] of [NAMECONNECT] work.",
    
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
        Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [2,3],
                        }],
            Text:  "[NAME] has some ability but needs to continue to work on [NAMECONNECT] [WKSKILLS] skills to bring greater consistency.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Progress: [1,2],
                        }],
            Text:  "[NAMECONNECT] [OUTPUTIS] still not quite where we would expect [NAMEREF] to be at this stage.",
    
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['Exam','Mock'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2,3],
                        }],
            Text: "[NAMECONNECT] test results indicate a few gaps in knowledge, notably [WKTOPICS]."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                        }],
            Text: "[NAMECONNECT] [OUTPUTHAS] a tendency to lack [COMFEED], [NAME] will need to address this next [TIME]."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAMECONNECT] [OUTPUTONLY] demonstrates a good understanding of the [STRTOPICS] topics we have covered."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['Exam'],
            Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAMECONNECT] recent paper reflected thorough revision and a firm understanding of [STRTOPICS]."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam'],
            Termexc: false,

            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAMECONNECT] [SUBJECT] ability is [ABILITY], as reflected in [NAMECONNECT] work throughout the [TIME]."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Intro',
            Context: ['General','Exam'],
            Termexc: true,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAME] has had a [ABILITY] term in [SUBJECT] and has met every challenge."
        
        },

        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'All',
            Context: ['General','Exam'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                        }],
            Text: "[NAME] has struggled with the amount of content this [TIME]."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'All',
            Context: ['General','Exam'],
            Termexc: false,
            Opinion: true,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                            Effort: [3,4,5],
                            Engagement: [3,4,5]
                        }],
            Text: "I know [NAME] will be a little disappointed with [NAMECONNECT] attainment."
        
        },
        {
            ReportType: ['Art','Technology','Sport'],
            Position: 'Filler',
            Context: ['General','Exam'],
            Termexc: false,
            Opinion: true,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                            Confidence: [3,4,5],
                            Progress: [3,4,5]
                        }],
            Text: "It has been encouraging to see [NAMEREF] performing with greater confidence, particularly when using [NAMECONNECT] [STRSKILLS] skills."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2,3],
                            Progress: [1,2]
                        }],
            Text: "At the moment, some of [NAMECONNECT] basic [SUBJECT] skills need work, such as [WKSKILLS] skills."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'Filler',
            Context: ['General','Exam'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAMECONNECT] [OUTPUTONLY] showed a secure grasp of the material covered."
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] uses [NAMECONNECT] skills and knowledge intelligently and has produced [ABILITY] [OUTPUTONLY].",
        
        },
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2],
                        }],
            Text: "It seems that [NAME] has still not come to terms with the level of [COMFEED] required in [NAMECONNECT] [OUTPUTONLY].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] has demonstrated [ABILITY] [COMPLUS] while playing [SPORT] and delivered some impressive performances.",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "When using [NAMECONNECT] [STRSKILLS] skills in [SPORT], [NAME] has demonstrates [ABILITY] [COMPLUS].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAMECONNECT] [COMPLUS] is [ABILITY] when playing [SPORT].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAMECONNECT] [COMPLUS] is [ABILITY] when using [NAMECONNECT] [STRSKILLS] skills in [SPORT].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2,3],
                        }],
            Text: "[NAME] has sound [COMPLUS], but I feel [NAME] could push [NAMESELF] to improve [NAMECONNECT] overall performances.",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] has displayed [ABILITY] [COMPLUS] in [SPORT] and when using [NAMECONNECT] [STRSKILLS] skills.",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] is a talented athlete who displays [ABILITY] [COMPLUS] when using [NAMECONNECT] [STRSKILLS] skills.",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] is a talented athlete who displays [ABILITY] [COMPLUS] when playing [SPORT].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAME] is a talented athlete with [ABILITY] potential in [SPORT].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAME] has [ABILITY] [STRSKILLS] skills when playing [SPORT].",
        
        },
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] is a talented athlete who has actively participated in [SPORT].",
        
        }, 
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: false,
            Connect: false,
            Patterns : [{
                            Attainment: [1,2,3],
                        }],
            Text: "[NAME] is capable of being an excellent athlete and will need to work on [NAMECONNECT] [COMFEED] to improve.",
        
        }, 
        {
            ReportType: ['Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [1,2,3,4,5],
                        }],
            Text: "[NAME] has displayed [ABILITY] [COMPLUS] in [SPORT].",
        
        }, 
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [1,2,3,4,5],
                        }],
            Text: "[NAMECONNECT] [STRSKILLS] skills are [ABILITY].",
        
        }, 
        {
            ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: false,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                        }],
            Text: "[NAME] has shown a [ABILITY] set of skills.",
        
        }, 
        {
            ReportType: ['Technology'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: false,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                            Creativity:[3,4,5]
                        }],
            Text: "[NAME] is talented, creative, and has a good sense of design.",
        
        }, 
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: true,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [3,4,5],
                            Creativity:[3,4,5]
                        }],
            Text: "I have been pleased with the standard of [NAMECONNECT] [OUTPUTONLY] which shows a good understanding of concepts and techniques.",
        
        }, 
        {
            ReportType: ['Art','Technology'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: true,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [2,3],
                            Creativity:[3,4,5],
                            Effort: [4,5]
                        }],
            Text: "Whilst not the most technically skilled in [SUBJECT], this is compensated by [NAMECONNECT] willingness to try things and to come up with personal and creative solutions.",
        
        },
        {
            ReportType: ['Art','Technology'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: true,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [2,3],
                            Creativity:[3,4,5],
                            Effort: [4,5]
                        }],
            Text: "[NAME] contributed to a number of [SUBJECTS] projects, and developed key skills.",
        
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'All',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: true,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAME] has taken to [STRSKILLS] with ease and accuracy, which has allowed [NAMECONNECT] to produce [ABILITY] [OUTPUTONLY].",
        
        },
        {
            ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
            Position: 'Filler',
            Context: ['General','Exam','Project'],
            Termexc: true,
            Opinion: true,
            Link: true,
            Connect: true,
            Patterns : [{
                            Attainment: [4,5],
                        }],
            Text: "[NAMECONNECT] [PROJECT] project was particularly inventive.",
        
        },


        


        

        

        

        
        
 

]








