export const Vocab = {

    

    //ability based vocab

    attainment: [
        // His ability is X - based on ability
        ["limited"],
        ["limited"],
        ["solid", 'decent','sound'],
        ["good", "impressive" ],
        ["outstanding", "excellent","fantastic", "great", "very good", 'wonderful', "very strong", "exceptional"] 
    ],
    attainmentdesc: [
        // He is a X student
        ["disappointing"],
        ["below average", "below par"],
        ["solid", "sound", "capable"],
        ["good", "talented", "well rounded", "very capable"],
        ["very talented", 'impressive', "excellent", "outstanding", "natural"]
    ],
    obtained: [
        // he x Skills this term - based on ability
        ["became aware of",],
        ["learned"],
        ["learned","understood"],
        ["understood","grasped"],
        ["mastered"]
    ],
    mastery:[
        ["stuggle with",],
        ["capacity for"],
        ["competency for"],
        ["proficiency for",'prowess for', 'skill for'],
        ["mastery of", "prowess for",'talent for']
    ],
    displayed: [
        // He has X confidence, He has X ability - based on ability
        ['lacked', 'been in of need of', 'required'],
        ['occasionally lacked'],
        ['displayed', 'demonstrated','shown'],
        ['displayed', 'demonstrated','shown','exhibited', 'consistently shown'],
        ['displayed', 'demonstrated','shown','breathed', 'exuded','radiated'],
    ],
    struggle: [
        // He X learning topics, In particular [NAME] has x [SKILLS] this [TIME]."
        ['struggled with'],
        ['struggled with'],
        ['taken to', 'developed'],
        ['enjoyed'],
        ['really enjoyed', 'mastered'],
    ],
    



    //progress based vocab

    progress: [
        // He has shown X progress - Based on progress
        ['limited','disappointing'],
        ["limited",'slow'],
        ["steady", "promising", "sound",'solid','firm','robust'],
        ["good", "positive", "significant"],
        ["outstanding", "excellent","fantastic", "great", "very good", 'wonderful', "very significant", "impressive"]
    ],
    progressDesc: [
        // He has developed X, he progressed X "Maximilian has performed [PROGRESSDESC] this [TIME]",
        ['poorly'],
        ['slowly'],
        ['steadily', 'solidly','firmly','robustly'],
        ['well','strongly'],
        ['very well','superbly','skillfully','wonderfully','extremely well'],
    ],

    progressed:[
        // he has X on a number of fronts
        ['struggled'],
        ['struggled'],
        ['developed','progressed'],
        ['shined'],
        ['excelled','stood out'],

    ],
    satisfied:[
        // [NAME] should be x with his development
        ['disappointed'],
        ['disappointed'],
        ['content'],
        ['pleased'],
        ['very happy', 'very pleased'],
    ],
    developing: ['progressing', 'developing', 'improving','refining','strengthening','building'],
    develop: ['improve', 'develop', 'strengthen','progress'],
    developed: ['developed', 'improved', 'progressed'],
    development: ['development', 'improvement', 'progress', 'inroads','steps forward'],
    works: ['worked hard','strived','aimed', 'endeavoured','tried hard','made a huge effort'],
    
    // engagement based vocab


    engage: [
        //engagedDesc - He is an X student
        ["seemingly uninterested"],
        ["passive and often disinterested"],
        ["quiet","calm",'relaxed'],
        ["engaged", "actively engaged",'enthusiastic','positive'],
        ["delightfully enthusiastic", "very engaged",'very positive'],
    ],
    
    approach: [
        // He approaches the subject with X - Based on engagement
        ["disinterest", "apathy"],
        ["only a partial enthusiasm"],
        ["patience and reflection"],
        ["enthusiasm", "inquisitive endeavour", 'positivity'],
        ["enthusiasm", "continued positivity", "passion"],
    ],




    // effort based vocab

    effort : [
        // He works X - based on effort
        ["without effort", "lazily"],
        ['little'],
        ['solidly', 'diligently'],
        ['hard', "rigorously"],
        ["very hard",'really hard',"extremely hard", "incredibly hard"],
    ],
    workethic : [
        // work ethic within lessons is X, he has shown a X work ethic - based on effort
        ["disappointing","poor"],
        ["disappointing",'poor'],
        ["pleasing"],
        ["very good", "impressive", "strong"],
        ["outstanding", "excellent","fantastic", "great", 'wonderful', "very strong","exceptional", "impressive",]
    ],
    character:[
        //He is a X student, she is a X learner - based on effort
        ["distracted", "preoccupied"],
        ['passive'],
        ['resolute',],
        ['diligent and industrious', "hard working" ,' inquisitive'],
        ["diligent and industrious", "very hard working"],
    ],
    workswith : [
        // He works with X, he has impressed with his x to set tasks - based on effort
        ['lack of effort','lack of perseverance','inattention'],
        ['lack of effort','lack of perseverance','inattention'],
        ['effort','diligence'],
        ["endeavour",'diligence','industry',"perseverance",'resolve'],
        ['dedication','tenacity',"endeavour",'industry',"perseverance",'resolve'],
    ],
    impression: [
        // I have been x with [NAME] this term, based on effort
        ['disappointed'],
        ['disappointed'],
        ['pleased'],
        ['impressed'],
        ['very impressed','blown away','inspired'],
    ],


    // Vocab based on type of report
    location: {
        general: ['class'], 
        science: ['class'],
        drama: ['class'],
        art: ['class'],
        technology: ['class'], 
        language: ['class'],
        sport: ['[SPORT] matches','[SPORT] competitions','[SPORT] practice'], 
    },
    
    learncontext: {
        general: ['understanding','knowledge'], 
        science: ['understanding','knowledge','skills','scientific analysis and understanding'],
        drama: ['understanding','knowledge','skills'],
        art: ['skills'],
        technology: ['talent','skills'], 
        language: ['understanding','language understanding','linguistic ability'],
        sport: ['skills','ability','technical ability','sporting ability'], 
    },
    worktype: {
        general: ['challenges', 'questions','tasks','assignments','written assignments'], 
        science: ['[SUBJECT] problems','assignments','tasks', 'questions','challenges'],
        drama: ['class exercises','exercises', 'practice','group work'], 
        art: ['[SUBJECT] assignments','projects','[SUBJECT] challenges'],
        technology: ['[SUBJECT] assignments','projects','[SUBJECT] challenges'], 
        language: ['set tasks', '[SUBJECT] exercises','tasks','written assignments'],
        sport: ['class exercises','exercises', '[SPORT] drills', '[SPORT] practice','practice'], 
    },
    outputonly: {
        general: ['written work', 'work'], 
        science: ['work'],
        drama: ['performances','work'],
        art: ['work','pieces','imaginative work'], 
        technology: ['work','imaginative work'], 
        language: ['[SUBJECT]','[SUBJECT] comprehension'],
        sport: ['performances','[SPORT] performances'], 
    },
    outputhas: {
        general: ['written work has', 'work has'], 
        science: ['work has'],
        drama: ['performances have'],
        art: ['work has','pieces have'], 
        technology: ['work has','pieces have'], 
        language: ['[SUBJECT] has','[SUBJECT] comprehension has'],
        sport: ['performances have','[SPORT] performances have'], 
    },
    outputis: {
        general: ['written work is', 'work is'], 
        science: ['work is'],
        drama: ['performances are'],
        art: ['[SUBJECT] work is','work is','[SUBJECT] pieces are'],
        technology: ['work is','pieces are'], 
        language: ['[SUBJECT] is','[SUBJECT] comprehension is'],
        sport: ['performances are','[SPORT] performances are'], 
    },
    topics: {
        general: [], 
        science: [],
        drama: ['characterisation'],
        art: [], 
        technology: [], 
        language: ['grammar','vocabulary','verb tenses'],
        sport: [], 
    },
    attributes: {
        general: ['Detail','Depth','Consistency','Structure','Accuracy','Quality'],
        science: ['Detail','Depth','Consistency','Structure','Accuracy','Quality'],
        drama: ['Voice Control','Consistency','Participation','Creative Process','Movement'],
        art: ['Creativity','Personality','Detail','Depth','Consistency','Accuracy','Quality'],
        technology: ['Creativity','Detail','Depth','Consistency','Accuracy','Quality'],
        language: ['Vocabulary','Grammatical Accuracy','Conjugation','Accuracy','Quality'],
        sport: ['Tactical Awareness','Technique','Endurance','Speed','Stength','Agility','Competitiveness','Quality'], 
    },
    objectdefault: {
        general: ['quality'],
        science: ['quality'],
        drama: ['quality'],
        art: ['quality'],
        technology: ['creative talent','creativity','talent'],
        language: ['linguistic ability'],
        sport: ['athletic ability','sporting ability','quality'], 
    },
    topicdefault: {
        general: ['topics'],
        science: ['topics'],
        drama: ['[SUBJECT] concepts'],
        art: ['[SUBJECT] concepts'],
        technology: ['[SUBJECT] concepts'],
        language: ['[SUBJECT]'],
        sport: ['[SPORT]'], 
    },
    skilldefault: {
        general: ['skills'],
        science: ['scientific skills'],
        drama: ['creative skills'],
        art: ['creative skills'],
        technology: ['creative skills'],
        language: ['language skills','translation'],
        sport: ['athletic skills'], 
    },
    skills: {
        general: ['critical thinking','communication'], 
        science: ['problem solving','communication'],
        drama: ['improvisation','communication','acting'],
        art: [],
        technology: ['problem solving'], 
        language: ['listening','writing', 'reading','speaking'],
        sport: [], 
        project: ['planning', 'designing', 'implementation'], 
        exam: ['exam']
    },
    capacity: [
        'potential',
        'ability',
        'capability',
        'capacity',
        'aptitude',
    ],
    worked: ['worked', 'performed'],

    // Exam technique vocab

    technique:{
        question: ['understanding the question asked', 'reading the question carefully'],
        process: ['outlining [NAMECONNECT] methodology and process', 'explaining [NAMECONNECT] points fully'],
        time: ['managing [NAMECONNECT] time carefully','testing [NAMECONNECT] technique under timed conditions','spending the right amount of time on each question','reducing the amount of time'],
        resources: ['utilising the resources available', 'studying the resources provided', 'using the data provided'],
        examples: ['including examples and key words', 'explaining [NAMECONNECT] points using examples'],
        consolidation: ['consolidating [NAMECONNECT] understanding']

    },

    exam:[
        // His ability is X - based on ability
        ["in need of urgent focus"],
        ["limited"],
        ["ok"],
        ["good", "impressive" ],
        ["outstanding", "excellent","fantastic", "great", "very good", 'wonderful', "very strong", "exceptional"] 
    ],
    ///

    introtext: ['Next [TIME],', 'Looking forward,', 'To progress further,', 'In terms of next steps,','Moving forward,','Looking to next [TIME],'],

}

