

export const Effort= [
    

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                     Engagement: [4,5],
                    }],
        Text: "[NAME] applies [NAMESELF] fully in lessons.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [2],
                    }],
        Text:  "I would like to see [NAMEREF] show more resilience and independence in [OTHWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                     Attainment: [3,4,5],
                    }],
        Text:  "[NAME] takes pride in [NAMECONNECT] work and completes [OTHWORKTYPE] efficiently."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    Engagement: [4,5],
                    }],
        Text:   "I have been [IMPRESSION] with [NAMECONNECT] “can-do” attitude in lessons."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Confidence: [1,2,3],
                    Attainment: [1,2,3],
                    }],
        Text:   "[NAME] actively engages in working carefully and conscientiously but [NAME] should seek clarification to improve [NAMECONNECT] [LEARNCONTEXT] further."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    }],
        Text:   "[NAME] actively engages in working carefully and conscientiously."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Progress: [3,4,5],
                    Confidence: [4,5],
                    }],
        Text:  "[NAME] has approached the various challenges with a mature attitude and has gained confidence."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    Progress: [4,5],
                    }],
        Text:  "[NAME] demonstrates [WORKETHIC] resilience and effort when approaching challenges."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Confidence: [4,5],
                    Engagement: [4,5],
                    }],
        Text:  "[NAME] tackles challenges head on and [NAMECONNECT] confidence when doing so is wonderful to observe."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    }],
        Text:  "[NAME] shows perseverance in all [NAME] does."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [
            {
                Effort: [2],
                Progress: [1,2],
                Engagement: [1,2]
            }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when learning about [WKTOPICS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
            Effort: [3,4,5],
            Progress: [3,4,5],
            Engagement: [3,4,5]
            }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when learning about [STRTOPICS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                Effort: [3,4,5],
                Progress: [3,4,5],
                Engagement: [3,4,5]
            }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when applying [NAMECONNECT] [STRSKILLS] skills to class challenges."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [
            {
                Effort: [2,3],
                Progress: [1,2],
                Engagement: [1,2]
            }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when applying [NAMECONNECT] [WKSKILLS] skills to class challenges."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                        Effort: [2],
                        Progress: [1,2],
                        Engagement: [1,2]
                    }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when developing [NAMECONNECT] [WKSKILLS] skills."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    Progress: [3,4,5],
                    Engagement: [3,4,5]
                    }],
        Text:  "I have been [IMPRESSION] with [NAMECONNECT] resilience, particularly when developing [NAMECONNECT] [STRSKILLS] skills."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    Progress: [3,4,5],
                    Engagement: [3,4,5]
                    },
                    {
                        Effort: [1,2],
                        Progress: [1,2],
                        Engagement: [1,2]
                    }],
        Text:  "I have been [IMPRESSION] with [NAMEACTUAL]'s endeavour and effort."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has worked [EFFORT].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2],
                        Attainment: [1,2],
                        }],
        Text:  "[NAMECONNECT] [WORKETHIC] effort is evident in [NAMECONNECT] [LEARNCONTEXT] and [NAME] should reflect on this.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] [WORKETHIC] effort is evident in [NAMECONNECT] [LEARNCONTEXT] and [NAME] should be proud of this.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                        Effort: [3],
                        Attainment: [1,2,3],
                        }],
        Text:  "[NAMECONNECT] effort, while [WORKETHIC], could still be improved upon.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Attainment: [4,5],
                    },
                    {
                        Effort: [1,2],
                        Attainment: [1,2],
                    }],
        Text:  "[NAME] has [IMPRESSION] with [NAMECONNECT] [WORKSWITH] towards developing [NAMECONNECT] [STRSKILLS] further.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
            Effort: [4,5],
            Attainment: [4,5],
            },
            {
                Effort: [2,3],
                Attainment: [1,2],
                Engagement: [1,2],
            }],
        Text:  "[NAME] has [IMPRESSION] with [NAMECONNECT] [WORKSWITH] to [OTHWORKTYPE].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has continued to work with [WORKSWITH] when using [NAMECONNECT] [STRSKILLS] skills in [OTHWORKTYPE].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] approaches [OTHWORKTYPE] with effort and resolve.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [2],
                    }],
        Text:  "[NAME] could approach [NAMECONNECT] [OTHWORKTYPE] with greater effort and resolve.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1,2],
                    Engagement: [1,2]
                    },
                    {
                        Effort: [4,5],
                        Engagement: [4,5]
                    }],
        Text:  "I was particularly [IMPRESSION] with [NAMECONNECT] [WORKSWITH] when learning about [STRTOPICS].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAMECONNECT] work on [NAMECONNECT] [PROJECT] project showed [WORKETHIC] [WORKSWITH].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] approaches each set challenge with [WORKSWITH].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] tackles problems and challenges head on and with [WORKSWITH].",
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    }],
        Text:  "[NAMECONNECT] [WORKSWITH] when tackling [OTHWORKTYPE] is [WORKETHIC]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Attainment: [3,4],
                    Effort: [2],
                    },
                    {
                        Attainment: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] work ethic has room for improvement."
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Engagement: [4,5],
                    }],
        Text:  "It has been a pleasure to teach [NAME] this [TIME], [NAMEREF] effort and engagement within lessons is [WORKETHIC]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3],
                    }],
        Text:  "[NAME] has applied [NAMESELF] [EFFORT] in class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [2,3,4,5],
                    }],
        Text:  "[NAMECONNECT] perseverance with challenges has been [WORKETHIC].",
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    Engagement: [3,4,5],
                    }],
        Text:  "[NAME] demonstrated [WORKETHIC] perseverance and dedictation with [NAMECONNECT] meticulously work on [NAMECONNECT] [PROJECT] project.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  '[NAME] has a [WORKETHIC] and resilient approach to [NAMECONNECT] [LEARNCONTEXT].',

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1],
                    Attainment: [1,2,3],
                    }],
        Text:  '[NAME] must try harder to improve [NAMECONNECT] [LEARNCONTEXT]. This begins with making attendance a priority.',

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [2],
                    Engagement: [1,2,3],
                    Attainment: [1,2,3,4],
                    }],
        Text:  '[NAME] should try and apply [NAMESELF] more fully in class.',

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1,2],
                    Attainment: [3,4,5],
                    Progress: [3,4,5],
                    }],
        Text:  'One area of disappointment, is [NAME] seems to be working in second gear. With a little more effort, [NAME] will reach an even higher level of quality.',

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    }],
        Text:  "[NAME] has certainly pushed [NAMESELF] this [TIME] and I have been [IMPRESSION] with [NAMECONNECT] [WORKSWITH].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] should be very proud of [NAMECONNECT] efforts, [NAME] has continually been self-assessing and improving elements of [NAMECONNECT] [LEARNCONTEXT].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    
                    }],
        Text:  "[NAME] should be very proud of [NAMECONNECT] [WORKETHIC] efforts."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has continually been self-assessing and improving [NAMECONNECT] understanding of [STRTOPICS].",

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has continually been self-assessing and improving [NAMECONNECT] [STRSKILLS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] demonstrates a resolute attitude especially when [NAME] finds the [OTHWORKTYPE] challenging.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1,2],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] limited effort in class is preventing [NAMEREF] from reaching a higher standard.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1],
                    Attainment: [1,2,3],
                    }],
        Text:  "Excessive absences and tardiness are having a less-than-positive effect on [NAMECONNECT] [LEARNCONTEXT].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1],
                    Attainment: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] significant number of absences have affected [NAMECONNECT] [LEARNCONTEXT].",

    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Attainment: [1,2,3,4,5],
                    }],
        Text:      "I believe [NAME] is capable of putting more effort into [NAMECONNECT] studies to boost [NAMECONNECT] [LEARNCONTEXT] further."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                    }],
        Text:      "[NAME] has continued to work hard within [NAMECONNECT] lessons and in [OTHWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3],
                        Attainment: [1,2,3],
                    }],
        Text:      "[NAME] should look to put more time and effort into [NAMECONNECT] homework to help [NAMEREF] consolidate [NAMECONNECT] understanding."
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Engagement: [1,2],
                    },
                    {
                        Effort: [3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text:      "I have been [IMPRESSION] with [NAMECONNECT] ability to apply [NAMESELF] to [OTHWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Engagement: [4,5],
                    }],
        Text:      "I believe that [NAMECONNECT] organisation or existing workload is impeding how much effort [NAME] can give to [SUBJECT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                        Attainment: [1,2,3],
                        Creativity: [1,2,3],
                    }],
        Text:      "I was particularly impressed by [NAMECONNECT] resilience when tackling [NAMECONNECT] [PROJECT] project, as at times [NAME] did not find it easy."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                        Attainment: [1,2,3],
                    }],
        Text:      "[NAME] demonstrated perseverance and determination when tackling [OTHWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3,4,5],
                        Attainment: [1,2,3],
                    }],
        Text:      "I have been [IMPRESSION] with [NAMECONNECT] response to the various challenges [NAME] has faced."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Creativity: [4,5],
                        Attainment: [1,2,3],
                    }],
        Text:      "[NAME] is capable of high quality [OUTPUTONLY], but [NAME] must apply [NAMESELF] more consistently to realise this potential."
    },
    {
        ReportType: ['Sport','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Creativity: [4,5],
                        Attainment: [3,4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "It has been great to see [NAMEREF] using [NAMECONNECT] creativity and experimenting with [NAMECONNECT] skills this [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1],
                        Attainment: [1,2,3],
                    }],
        Text:      "[NAME] has missed several lessons this [TIME] which has impacted [NAMECONNECT] learning."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Progress: [3,4,5],
                    Effort: [4,5],
                    }],
        Text: "[NAMECONNECT] perserverance and resolve has impressed when studying and developing [NAMECONNECT] understanding of [STRTOPICS].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [4,5],
                    }],
        Text: "[NAME] is a self-motivated student who works to ensure [NAMECONNECT] work is completed on time.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [1,2],
                    Engagement: [4,5],
                    Attainment: [1,2,3,4],
                    }],
        Text: "I am concerned about [NAMECONNECT] organisational skills and [NAMECONNECT] ability to turn assignments in on time.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text: "[NAME] is conscientious and shows [WORKETHIC] [WORKSWITH] when completing [NAMECONNECT] [OTHWORKTYPE].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Focus: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                    }],
        Text: "[NAME] is working hard on improving [NAMECONNECT] exam technique."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Focus: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Progress: [3,4,5],
                    }],
        Text: "[NAME] has worked hard to improve [NAMECONNECT] [LEARNCONTEXT], making [WORKETHIC] use of feedback."
    
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Focus: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Progress: [3,4,5],
                    }],
        Text: "[NAME] has worked hard to improve [NAMECONNECT] [STRSKILLS] skills with every task."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Focus: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Progress: [3,4,5],
                    }],
        Text: "[NAME] has worked hard to improve [NAMECONNECT] understanding of [STRTOPICS] in every class."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Focus: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [4,5],
                        Confidence: [3,4,5],
                    }],
        Text: "[NAME] strives to solve problems [NAMESELF] before seeking help."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Focus: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [3,4,5],
                        Progress: [3,4,5],
                    }],
        Text: "[NAME] has been working [EFFORT] this [TIME] to get a full grasp of [STRTOPICS]."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] has continued to work [EFFORT] in [SUBJECT] both inside and outside of class.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [2],
                    }],
        Text:      "[NAME] is not always as diligent and productive as [NAME] could be."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] has worked with committment and focus this [TIME] on [NAMECONNECT] [PROJECT] project."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Attainment: [1,2,3],
                    }],
        Text:   "[NAME] exhibits poor time management and consequently has trouble completing assignments on time.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [2,3],
                        Progress: [1,2,3],
                        Confidence: [1,2,3]
                    }],
        Text:   "[NAME] has a tendency to give up too quickly when working on [OTHWORKTYPE].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [1,2],
                    }],
        Text:   "[NAME] still has some way to go to meet the expectations in terms of effort.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [2,3],
                        Confidence: [1,2]
                    }],
        Text:   "[NAME] can be reluctant to push [NAMESELF] when an answer is not immediately apparent.",
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [4,5],
                    }],
        Text:   "[NAME] is prepared to endeavour with those aspects [NAME] finds less intuitive.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Confidence: [3,4,5],
                        Effort: [3,4,5],
                        Progress: [3,4,5],
                    }],
        Text:      "I have been pleased by [NAMECONNECT] approach to practicals where [NAME] is developing the ability to work independently and take responsibility for [NAMECONNECT] own learning."

    }, 
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2,3],
                        Effort: [2],
                        Progress: [1,2,3],
                    }],
        Text:      "[NAME] needs to work with greater determination if [NAME] is to improve."

    }, 
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3],
                        Effort: [2],
                        Progress: [1,2,3],
                    }],
        Text:  "At times [NAME] can give up too easily and a little more resilience would go a long way.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2,3],
                        Effort: [2],
                        Progress: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] [OUTPUTONLY] often suggest a lack of care and industry.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5]
                    }],
        Text: "[NAMECONNECT] diligent approach has given [NAMEREF] the best possible chance of doing well in the exam.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5]
                    }],
        Text: "[NAME] has worked hard to improve [NAMECONNECT] [LEARNCONTEXT] and we are beginning to see the effects of this within [NAMECONNECT] [OUTPUTONLY].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Confidence: [1,2]
                    }],
        Text: "While [NAME] lacks confidence when using [NAMECONNECT] [STRSKILLS] skills, I am always impressed with [NAMECONNECT] consistent efforts to improve.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [4,5]
                    }],
        Text: "[NAME] demonstrates a mature attitude and reflects carefully on feedback to develop [NAMECONNECT] understanding.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [3,4,5],
                    }],
        Text: "[NAMECONNECT] response to feedback and general determination to improve are [WORKETHIC].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [4,5],
                    }],
        Text: "[NAMECONNECT] drive to achieve highly is clear in every lesson.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Attainment: [1,2]
                    }],
        Text: "[NAME] is conscientious and I am sure that [NAME] will work hard to address the gaps in [NAMECONNECT] [LEARNCONTEXT].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Attainment: [1,2,3],
                        Engagement: [3,4,5]
                    }],
        Text: "To show [NAMECONNECT] true ability, [NAME] needs to improve [NAMECONNECT] organisation and time management skills.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5]
                    }],
        Text: "[NAMEACTUAL]'s keenness to improve [NAMECONNECT] [SUBJECT] skills makes [NAMEREF] a pleasure to teach.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Engagement: [1,2]
                    }],
        Text: "[NAMECONNECT] slightly apathetic approach to [SUBJECT] can be a hindrance when picking up new topics.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Engagement: [1,2]
                    }],
        Text: "[NAMECONNECT] effort in class fluctuates, meaning [NAME] does not always apply [NAMESELF] fully to [NAMECONNECT] [OUTPUTONLY].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [1,2],
                        Engagement: [1,2],
                    }],
        Text: "[NAME] could do very well with greater intent.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] is keen to extend [NAMECONNECT] understanding by working on [OTHWORKTYPE].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "In class, [NAME] has pushed [NAMESELF] to improve [NAMECONNECT] [STRSKILLS] skills.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: true,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] conducts work and exercises with care, attention and resilience.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] has worked incredibly hard this [TIME], showing focus and resilience with every task.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] has worked hard to learn [STRTOPICS].",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "I have been impressed by [NAMECONNECT] tenacity and resolve.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] responds well to feedback, and is able to evaluate [NAMECONNECT] [OUTPUTONLY] accurately.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[NAME] has impressed me with the diligence and detail [NAME] has applied to all of our challenges.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                    }],
        Text: "At times, [NAME] is not always sufficiently or consistently resolute when completing [OTHWORKTYPE].",
    
    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                    }],
        Text: "[NAME] shows courage and commitment to [NAMECONNECT] role on stage.",
    
    },

    
    
]









