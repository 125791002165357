import React, { useState } from "react";

import "./selectInput.css";

import HelpModal from "../../HelpModal/helpModal";
import HelpButton from "../helpButton";

const SelectInput = ({ question, withInputChange }) => {

    const [helpToggle, setHelpToggle] = useState(false);

  


    let selectItems;

    if (question.options) {
      selectItems = question.options.map((option, key) => {
        return (
          <option key={key} value={option}>
            {" "}
            {option}
          </option>
        );
      });
    } else {
      selectItems = <option value="no options"> No Options</option>;


    }

  const toggleModal = () => {
    window.scrollTo(0, 0);
    setHelpToggle(()=>!helpToggle)
  }

  return (
    <>
    <div className="inputWrap">
        <div className="flexWrapSpaceBet inline" >
            <label className="fw5">{question.label}</label>
          
            <HelpButton clicked={toggleModal}/>

        </div>

      <select
        id={question.id}
        value={question.value}
        onChange={(e) => withInputChange(e.target.value, e.target.id)}
      >
        {selectItems}
      </select>

      
    </div>

    <HelpModal question={question} show={helpToggle} clicked={toggleModal}/>
    </>
  );
};

export default SelectInput;
