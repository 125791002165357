
export const Steps = [

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [4,5],
                    }],
        Text:  "[INTROTEXT] [NAME] should look to develop [NAMECONNECT] [LEARNCONTEXT] further by improving the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4,5],
                    }],
        Text:  "[INTROTEXT] [NAME] should look to develop [NAMECONNECT] [PROJECT] project further by working on the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."
        

    }, 
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4,5],
                        Effort: [3,4,5],
                        Engagement: [4,5],
                    }],
        Text:  "[INTROTEXT] [NAME] should work to develop the [COMFEED] of [NAMECONNECT] [OUTPUTONLY] and by doing see [NAMECONNECT] [LEARNCONTEXT] improve further."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5],
                        Effort: [4,5],
                        Engagement: [4,5],
                    }],
        Text:  "[INTROTEXT] [NAME] should look to replicate [NAMECONNECT] progress this [TIME], an excellent [TIME], well done."
        
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                    }],
        Text:  "I hope that [NAME] continues to apply [NAMECONNECT] positive attitude next [TIME]"
        
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                        Attainment: [1,2],
                    }],
        Text:  "[NAMEACTUAL] has potential to thrive in [SUBJECT], I'd like to see [NAMEREF] engage more positively in class next [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2,3],
                        Attainment: [4,5],
                    }],
        Text:  "[INTROTEXT] I'd like to see [NAMEACTUAL] utilise [NAMECONNECT] knowledge and skill to help [NAMECONNECT] classmates develop."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                        Progress: [1,2],
                        Attainment: [1,2,3,4]

                    }],
        Text:  "[INTROTEXT] [NAME] should endeavour be more disciplined with [NAMESELF] and focus on improving the overall quality of [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Effort: [4,5],
                        Progress: [4,5],
                    }],
        Text:  "[INTROTEXT] I would encourage [NAMEACTUAL] to keep pushing [NAMESELF] and focus on improving the [COMFEED] of [NAMECONNECT] [OUTPUTONLY] even further." 
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [2,4,5],
                    }],
        Text:  "[INTROTEXT] I would like [NAMEACTUAL] to consider how [NAME] can use [NAMECONNECT] talent to excel further within [SUBJECT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Engagement: [3],
                    }],
        Text:  "[INTROTEXT] I would like to see [NAMEREF] speak up more during lessons and to have more confidence in [NAMECONNECT] abilities."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [3],
                        Confidence: [1,2,3]
                    }],
        Text:  "[INTROTEXT] [NAME] should have more confidence in [NAMECONNECT] [ABILITY] ideas, I'd like [NAMEREF] to share these as they are often interesting and insightful."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5],
                    }],
        Text:  "I look forward to [NAMEACTUAL] [DEVELOPING] [NAMECONNECT] [WKSKILLS] skills even further next [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [2,3,4],
                        Effort: [4,5],
                    }],
        Text:    "[INTROTEXT] I would now like [NAMEACTUAL] to focus on the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [2,3.4],
                        Confidence: [1,2,3]
                    }],
        Text:    "[INTROTEXT] I would like to see [NAMEACTUAL] show more confidence in [NAMECONNECT] abilities and enjoy [NAMECONNECT] time in class more."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5],
                        Progress: [4,5],
                    }],
        Text:    "[INTROTEXT] I'm excited to see how [NAME] applies and develops [NAMECONNECT] [ABILITY] [WKSKILLS] skills further."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [1,2,3],
                        Confidence: [1,2,3]
                    }],
        Text:    "I would like to see [NAMEREF] try to contribute more in class so [NAME] can demonstrate [NAMECONNECT] [LEARNCONTEXT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [5],
                        Engagement: [5],
                        Effort: [5],
                    }],
        Text:   "[NAME] has been a pleasure to teach this [TIME]. Keep going."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Confidence: [1,2,3],
                        Effort: [1,2,3],
                        Engagement: [4,5]
                    }],
        Text:   "[INTROTEXT] I would like [NAMEACTUAL] to be more resilient with [OTHWORKTYPE]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [4,5],
                        Confidence: [1,2,3]
                    }],
        Text:   "[INTROTEXT] I would like [NAMEREF] to be more resilient when working on [NAMECONNECT] [LEARNCONTEXT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5]
                    }],
        Text:   "[INTROTEXT] [NAME] should now focus on injecting further [COMFEED] into [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Engagement: [4,5]
                    }],
        Text:   "[INTROTEXT] [NAME] should consolidate [NAMECONNECT] [LEARNCONTEXT] through self study."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Engagement: [4,5]
                    }],
        Text:  "[INTROTEXT] [NAME] should focus on consolidating [NAMECONNECT] understanding through a prioritised revision schedule."
    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [1,2,3],
                    }],
        Text:  'I would like [NAMEACTUAL] to consider how [NAME] can approach [SUBJECT] to showcase and develop [NAMECONNECT] [LEARNCONTEXT] further.'
    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:  'I would like [NAMEACTUAL] to continue to feed [NAMECONNECT] enthusiasm for [SUBJECT] by reading around the subject online.'

    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:  'I would like [NAMEACTUAL] to continue to feed [NAMECONNECT] enthusiasm for [SUBJECT] by practising [NAMECONNECT] learnt skills.'

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5]
                    }],
        Text:  '[INTROTEXT] [NAME] should now look to [DEVELOP] [NAMECONNECT] [OUTPUTONLY] with greater [COMFEED].'

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5]
                    }],
        Text:  "[INTROTEXT] [NAME] should now look to [DEVELOP] the [COMFEED] of [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5]
                    }],
        Text:  "[INTROTEXT] [NAME] should commit to [DEVELOPING] [NAMECONNECT] [COMFEED]."

    },
    {
        ReportType: ['General', 'Science','Art','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2],
                        Attainment: [1,2],
                        Progress: [1,2]
                    }],
        Text:  '[INTROTEXT] [NAMEACTUAL] must ensure that [NAME] keeps up with the internal deadlines to make sure [NAME] does not fall behind.'

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4,5],
                    }],
        Text:   "[INTROTEXT] [NAME] should commit to improving [NAMECONNECT] [PROJECT] project with greater [COMFEED]."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3,4,5],
                    Effort: [2,3,4,5],
                    Engagement: [3,4,5]
                    }],
        Text:   "[INTROTEXT] [NAMECONNECT] focus should be [DEVELOPING] [NAMECONNECT] [COMFEED] and extending [NAMECONNECT] [LEARNCONTEXT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5]
                    }],
        Text:   "I look forward to [NAMEACTUAL] being able to show more of [NAMECONNECT] talents next [TIME] when [NAME] faces new challenges."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5]
                    }],
        Text:   "I look forward to seeing what [NAMEACTUAL] can achieve next [TIME] when [NAME] will be tasked with more challenging [OTHWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5]
                    }],
        Text:   "I look forward to [NAMEACTUAL] being able to expand [NAMECONNECT] [LEARNCONTEXT] further next [TIME]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5]
                    }],
        Text:   "I look forward to [NAMEACTUAL] stretching [NAMECONNECT] skillset even further next [TIME]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5]
                    }],
        Text:   "It would be good for [NAMEACTUAL] to work on [NAMECONNECT] [COMFEED] next [TIME]."

    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Effort: [4,5],
                        Engagement: [2,4,5]
                    }],
        Text:   "[INTROTEXT] I would suggest that [NAME] keeps [DEVELOPING] [NAMECONNECT] [LEARNCONTEXT] further by setting [NAMESELF] [NAMECONNECT] own small personal challenges."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                        Engagement: [2,4,5]
                    }],
        Text:   "[INTROTEXT] I would recommend that [NAME] sets [NAMESELF] [NAMECONNECT] own personal challenges to enable [NAMEREF] to keep [DEVELOPING] [NAMECONNECT] [LEARNCONTEXT]."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [2,3,4,5],
                        Effort: [4,5],
                        Engagement: [4,5]
                    }],
        Text:   "[INTROTEXT] I would suggest that [NAME] keeps [DEVELOPING] [NAMECONNECT] understanding and interest of [SUBJECT] through continued reading."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [4,5]
                    }],
        Text:  "[INTROTEXT] I would like [NAMEACTUAL] to be more resilient with [OTHWORKTYPE], especially [NAMECONNECT] homework."  


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3,4],
                        Engagement: [1,2]
                    }],
        Text:  "[INTROTEXT] [NAME] must be more disciplined with [NAMESELF] and focus on improving [NAMESELF] further."


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3,4],
                        Engagement: [1,2]
                    }],
        Text:  "[INTROTEXT] [NAME] must be more disciplined with [NAMESELF] to focus on the [OTHWORKTYPE] rather than socialising."


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [2,3,4,5]
                    }],
        Text:  "[INTROTEXT] It is important that [NAME] continues to work on [NAMECONNECT] [COMFEED] in future [OTHWORKTYPE]."



    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [2,3,4,5]
                    }],
        Text:  "I still feel that [NAME] needs to improve the [COMFEED] of [NAMECONNECT] [OUTPUTONLY].  Doing so will help [NAMEACTUAL] step up a level in overall quality."



    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [2,3,4,5]
                    }],
        Text:  "[INTROTEXT] [NAMEACTUAL] must strive to be include greater [COMFEED] within [NAMECONNECT] [OUTPUTONLY] to ensure overall quality."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [2,3,4,5]
                    }],
        Text:  "[INTROTEXT] [NAMEACTUAL] should now focus on improving [NAMECONNECT] [COMFEED] and approach new [OTHWORKTYPE] with this in mind."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [3,4,5],
                        Attainment: [3,4,5]
                    }],
        Text:  "[INTROTEXT] [NAMEACTUAL] should now consider how [NAME] can work on [NAMECONNECT] [COMFEED], especially when tackling new [OTHWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Confidence: [1,2,3],
                        Engagement: [3]
                    }],
        Text:  "[INTROTEXT] I would like to see [NAMEREF] develop the confidence to share [NAMECONNECT] thoughts and ideas during class discussion."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3]
                    }],
        Text:  "[INTROTEXT] [NAME] should consider getting the thoughts and opinions of others in order to develop and expand [NAMECONNECT] ideas and approach."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[3,4,5],
                        Progress:[2,3,4,5],
                        Engagement:[3,4,5]
                    }],
        Text:  "[INTROTEXT] it is important that [NAME] gets into the habit of working on [NAMECONNECT] [COMFEED] with every challenge."

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2,4,5]
                    }],
        Text:  "I would suggest [NAME] starts reading around the subject as this will expand [NAMECONNECT] awareness and interest, hopefully inspiring [NAMECONNECT] continued progress in [SUBJECT]."

    },
    {
        ReportType: ['General'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [1,2]
                    }],
        Text:  "I would like to see [NAMEACTUAL] use the class time as constructively as possible and ensure [NAME] meets all internal deadlines."

    },
    {
        ReportType: ['General', 'Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[3,4,5],
                        Progress:[4,5],
                        Engagement:[3,4,5]
                    }],
        Text:  "[NAME] should commit to refining the [COMFEED] of [NAMECONNECT] [OUTPUTONLY] to improve [NAMECONNECT] overall quality."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                        Attainment: [4,5],
                        Progress: [4,5]
                    }],
        Text:  "[NAMEACTUAL] has tirelessly displayed a keen interest in [SUBJECT] and should be very proud of [NAMECONNECT] learnt [LEARNCONTEXT], Well Done!"


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5]
                    }],
        Text:  "While [NAMEACTUAL] has a number of development areas, notably improving [NAMECONNECT] [COMFEED], the final word must be about [NAMECONNECT] [PROGRESS] progress this [TIME]. Well Done!"

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                        Attainment: [4,5],
                        Progress: [4,5]
                    }],
        Text:  "It has been a delight teaching [NAMEREF] this [TIME]. Well Done!"
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                        Attainment: [1,2,3],
                        Progress: [1,2]
                    }],
        Text:  "Given [NAMEACTUAL] has struggled with organisation this [TIME], [NAME] should start to consider how [NAME] will manage this time to increase [NAMECONNECT] dedication to [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[3,4,5],
                        Progress:[4,5],
                        Engagement:[3,4,5]
                    }],
        Text:  "[NAMEACTUAL]'s priority is now on [DEVELOPING] [NAMECONNECT] [COMFEED]"

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                     Attainment:[3,4,5],
                    }],
        Text:  "I have every confidence that with the right revision schedule, [NAME] will be able to consolidate [NAMECONNECT] theoretical understanding for the exam."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment:[4,5],
                        Effort:[4,5],
                        Progress:[4,5],
                        Engagement:[4,5]
                    }],
        Text:  "I really cannot fault [NAMECONNECT] approach to [SUBJECT]; [NAME] has been a complete pleasure to teach."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment:[4,5],
                        Confidence:[1,2,3],
                        Engagement: [4,5]
                    }],
        Text:  "[INTROTEXT] [NAME] should try to have greater confidence in [NAMECONNECT] ability when tackling [OTHWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment:[3,4,5],
                        Progress: [4,5],
                        Effort: [4,5],
                        Engagement:[3],
                        Confidence:[1,2,3]
                    }],
        Text:  "[NAME] should feel really proud of what [NAME] has achieved so far and feel more confident to contribute in lessons. [NAME] is a pleasure to teach."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3] 
                    }],
        Text:  "[NAME] is struggling with [NAMECONNECT] [COMFEED] and so will need to make this a focus next [TIME]"

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[1,2,3],
                        Attainment: [1,2,3,4],
                        Engagement: [1,2,4]
                    }],
        Text:  "I believe [NAMEACTUAL] is capable of more than I have seen so far. With more effort, [NAME] will see [NAMECONNECT] skills and understanding improve."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[1],
                        Engagement: [1,2]

                    }],
        Text:  "It is really important that [NAMEACTUAL] improves [NAMECONNECT] attendance and puts more effort into [NAMECONNECT] studies outside the classroom to achieve what [NAME] is capable of."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort:[1,2,3],
                        Engagement: [1,2] 
                    }],
        Text:  "[INTROTEXT] I would like [NAMEACTUAL] to ensure [NAME] gives [NAMECONNECT] best to every task [NAME] is set."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort:[1,2,3],
                        Attainment: [3,4,5],
                        Creativity: [3,4,5]

                    }],
        Text:  "[NAMEACTUAL] is a capable student and I hope that [NAME] realises this as [NAME] has real potential if [NAME] applies [NAMESELF] further."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],

                    }],
        Text:  "While [NAMEACTUAL] has shown an excellent aptitude for [SUBJECT], [NAME] should look to improve [NAMECONNECT] [COMFEED] to take [NAMECONNECT] skill further."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [2,3,4,5],
                    }],
        Text:  "[NAME] should look to demonstrate [COMFEED] within [NAMECONNECT] [OUTPUTONLY]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text:  "[NAMEACTUAL] must ensure [NAME] continues to develop [NAMECONNECT] [COMFEED]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [1,2],
                    }],
        Text:  "[INTROTEXT] approaching [OTHWORKTYPE] with a positive attitude is the foundational requirement for [NAMEACTUAL] to [DEVELOP]"

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Progress: [4,5],
                        Effort: [4,5],
                        Engagement: [2,3,4],
                        Confidence: [1,2,3],
                    }],
        Text:  "I hope this positive feedback encourages [NAMEACTUAL] to believe in [NAMESELF] and speak up more during class discussion."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [1,2],
                    }],
        Text:  "[NAME] must be more disciplined with [NAMESELF] as this will allow [NAMEREF] to achieve greater results."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [3,4,5],
                        Confidence: [1,2,3],
                        Creativity: [3,4,5]
                    }],
        Text:  "I would like to see [NAMECONNECT] confidence grow as [NAME] is very able, well done!"
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Effort: [4,5],
                        Engagement: [4,5],
                        Creativity: [3,4,5]
                    }],
        Text:  "It will be interesting to see [NAMEACTUAL]’s ability tested when we tackle new [OTHWORKTYPE] next [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [1,2,3,4],
                        Effort: [2,3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text:  "[INTROTEXT] [NAME] should work to ensure [NAMECONNECT] [OUTPUTONLY] contains a greater level of [COMFEED]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Engagement: [4,5],
                        Confidence: [4,5],
                    }],
        Text:  "[NAMEACTUAL] has demonstrated [NAME] is very able, I look forward to what [NAME] can achieve next [TIME]."
    },
    {
        ReportType: ['Art','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Creativity: [3,4,5],
                        Engagement: [4,5],
                    }],
        Text:  "[NAME] should now consider how [NAME] can creatively tackle new [OTHWORKTYPE] to [DEVELOP] [NAMECONNECT] natural ability. Good work [NAMEACTUAL]."
    },
    {
        ReportType: ['Art','Technology','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [4,5],
                        Creativity: [3,4,5],
                        Engagement: [4,5],
                    }],
        Text:  "[NAME] should now consider how [NAME] can use [NAMECONNECT] [OBJECTDEFAULT] and skills to tackle new [OTHWORKTYPE] and stretch [NAMECONNECT] natural ability. Good work [NAMEACTUAL]."
    },

    {
        ReportType: ['Art','Technology'], 
        Position: 'Filler',
        Context: ['General'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Creativity: [3,4,5],
                        Engagement: [3,4,5],
                        Confidence: [1,2,3]
                    }],
        Text:  "I would encourage [NAMEACTUAL] to push [NAMESELF] creatively to reach [NAMECONNECT] full potential; what can [NAME] do with [NAMECONNECT] talent? I look forward to seeing [NAMECONNECT] future work."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [1,2,3],
                        Effort: [1,2,3],
                        Engagement: [1,2,3],
                    }],
        Text:  "[NAMECONNECT] focus should now on developing [NAMECONNECT] concentration skills so that [NAME] is fully engaged within group activities."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [1,2,3],
                        Effort: [1,2,3],
                        Engagement: [1,2,3],
  
                    }],
        Text:  "I would now like [NAMEACTUAL] to increase [NAMECONNECT] focus when tackling [OTHWORKTYPE] next [TIME], allowing [NAMEREF] to reach [NAMECONNECT] full potential."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [4,5],
                        Effort: [4,5],
                        Engagement: [4,5],
  
                    }],
        Text:  "[NAMEACTUAL] has made an effort to develop this [TIME], I hope [NAME] continues with this next [TIME]. Well Done."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Progress: [2,3],
                        Effort: [1,2,3],
                        Engagement: [1,2],
  
                    }],
        Text:  "I do hope that next [TIME] [NAMEACTUAL] strives to develop [NAMECONNECT] interest otherwise I fear [NAME] will not be able to unlock [NAMECONNECT] full potential."


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [3,4,5],
                    Effort: [3,4,5],
                    Attainment: [1,2,3,4],
                    }],
        Text:  "[INTROTEXT] [NAME] needs to regularly review [NAMECONNECT] class notes to consolidate [NAMECONNECT] understanding."


    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Effort: [3,4,5],
                        Attainment: [1,2,3,4],
  
                    }],
        Text:  "[NAME] should now prepare [NAMESELF] for the challenges [NAME] will face next [TIME] by working on [NAMECONNECT] [COMFEED]."


    },
    {
        ReportType: ['Art','Technology'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Creativity: [4,5],
  
                    }],
        Text:  "[NAMEACTUAL]’s challenge next [TIME] will be utilise and show [NAMECONNECT] [OBJECTDEFAULT] in [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['General','Science','Sport','Art','Drama','Technology','Language'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Creativity: [1,2,3],
                        Attainment: [4,5],

                    }],
        Text:  "[NAMECONNECT] focus will be to show more [COMFEED] in [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['Sport','Art','Drama','Technology','Language'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Creativity: [4,5],
                        Attainment: [3,4,5],
                        Confidence: [1,2,3],
                        Engagement: [2,4,5],

                    }],
        Text:  "[INTROTEXT] I would like [NAMEACTUAL] to be ambitious and confident with [NAMECONNECT] [OBJECTDEFAULT]."
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'], 
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [1,2,3],
                        Attainment: [4,5],
                        Progress: [4,5],
                        Engagement: [4,5],

                    }],
        Text:  "While [NAMECONNECT] organisation could be better, this should not detract from a [ABILITY] [TIME] of work."
    },
    {
        ReportType: ['Science','General'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Attainment: [3,4],
                        Engagement: [4,5],

                    }],
        Text:  "[INTROTEXT] [NAME] must take more time when completing set work and use [NAMECONNECT] notes and resources when doing so."
    },
    {
        ReportType: ['Art','Technology'], 
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2,4,5],
                    }],
        Text:  "I hope to see [NAMECONNECT] creative curiosity grow when working on [NAMECONNECT] [OUTPUTONLY] next [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:  "I would like to see [NAMEACTUAL] taking a bit more interest and responsibility for [NAMECONNECT] own learning, doing so will see [NAMECONNECT] understanding deepen and expand."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [1,2,3],
                        Engagement: [1,2,3],
                    }],
        Text:  "I implore [NAMEACTUAL] to keep pushing [NAMESELF] and to seek help if necessary."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Effort: [1,2,3],
                    Engagement: [3,4,5]
                    }],
        Text: "[INTROTEXT] [NAME] should try to pay attention to the [COMFEED] of [NAMECONNECT] [OUTPUTONLY] and be more resilient when tackling [OTHWORKTYPE]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    Exam: [1,2,3],
                    }],
        Text:  "[INTROTEXT] [NAME] will need to specifically focus on exam technique.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [4,5],
                    Attainment: [4,5],
                    Engagement: [4,5],
                    Effort: [4,5],
                    }],
        Text:  "[NAME] should be very proud of all that [NAME] has achieved this [TIME].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    Attainment: [3,4,5],
                    }],
        Text:  "[INTROTEXT] going through [WKTOPICS] would be beneficial to [NAMEREF].",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [4,5],
                    Effort: [4,5],
                    Engagement: [2,4,5],
                    }],
        Text:  "If [NAME] can maintain this level of effort and concentration throughout next [TIME], [NAME] will continue to do very well.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2,3],
                    Effort: [1,2],
                    Engagement: [1,2],
                    }],
        Text:  "[INTROTEXT] I sincerely hope that [NAME] steps up a gear.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam', 'General','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Progress: [1,2,3],
                    Effort: [1,2],
                    Engagement: [1,2,3],
                    }],
        Text:  "[INTROTEXT] [NAME] must return resolved to improve.",

    },
    {
        ReportType: ['Drama','Art'],
        Position: 'Filler',
        Context: ['Exam', 'General','Project'],
        Termexc: false,
        Skills: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [
                    {
                    Creativity: [1,2,3],
                    Effort: [3,4,5],
                    Engagement: [4,5],
                    }],
        Text:  "[INTROTEXT] I would like [NAME] to take more risks in [NAMECONNECT] [OUTPUTONLY] to really show [NAMECONNECT] best.",

    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text: "[INTROTEXT] [NAME] must maintain [NAMECONNECT] focus on stage and in rehearsal.",
    
    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Confidence: [1,2,3],
                        Effort: [3,4,5],
                        Engagement: [3,4,5],
                    }],
        Text: "[INTROTEXT] [NAME] should look to take more risks in [NAMECONNECT] choice of characters.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text: "[INTROTEXT] A more self-disciplined approach is required to develop [NAMECONNECT] skills and enjoyment of this subject.",
    
    },


    

    

]



		
		

		










