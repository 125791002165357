import React from 'react';
import { IoHelp } from "react-icons/io5";

const HelpButton = ({clicked}) => {





 return (
    <div className="helpButton point" onClick={clicked}>
        <IoHelp />
    </div>
 )

}

export default HelpButton;