import React from 'react';
import './Banner.css';

const Banner = (() => {


    const feedback = () => {
        const url = 'https://www.surveymonkey.co.uk/r/62Y6BYP'
        window.open(url, '_blank');
    }

    const buymeacoffee= () => {
        const url = 'https://www.buymeacoffee.com/nicholasgreen'
        window.open(url, '_blank');
    }


    


 return (


    



    <div className='banner'>

        <div className='bannerWrap'>

                <div className='firstBannerSection'>
                    <p className='hover pointer' onClick={buymeacoffee}>Buy me a Coffee?</p>
                </div>


                <div className='middleBannerSection'>
                    <h1 className='title'>
                        An app to <span className='pColor bold'>help teachers</span> write reports
                    </h1>
                </div>

                <div className='lastBannerSection'>
                    <button className='picButton' onClick={feedback}>

                        <p>Provide Feedback</p>
                    </button>

              
                </div>

        </div>


        <div className='bannerSpace'></div>


         
     </div>
 )

})

export default Banner;