import React, { useState } from "react";

import "./checkInput.css";

import HelpModal from "../../HelpModal/helpModal";
import HelpButton from "../helpButton";
import CheckBox from "./checkBox";

const CheckInput = ({ question, withInputChange }) => {

    const [helpToggle, setHelpToggle] = useState(false);

  
  

  const push = (e) => {


    let temp = question.value.map((check,i)=>{
        if (i === e){
          

          return {label: check.label, check: !check.check, value: check.value}
        }else{
          return check
        }

    })

    

    withInputChange(temp, question.id);

  }




  const toggleModal = () => {
    window.scrollTo(0, 0);
    setHelpToggle(()=>!helpToggle)
  }

  return (
    <>
    <div className="inputWrap">

        

        <div className="flexWrapSpaceBet inline" >
            <label className="fw5">{question.label}</label>
          
            <HelpButton clicked={toggleModal}/>

        </div>

        <div className='flexWrapStart'>

        {
          question.value.map((option,i)=>{

            return <CheckBox change={push} option={option} id={i}/>
          })

        }

        </div>


      
    </div>

    <HelpModal question={question} show={helpToggle} clicked={toggleModal}/>
    </>
  );
};

export default CheckInput;
