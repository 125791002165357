import React, { useState } from "react";

import Tag from "../../Tag/Tag";
import "./multipleInput.css";

import HelpModal from "../../HelpModal/helpModal";
import HelpButton from "../helpButton";

const MultipleInput = ({ question, withInputChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [helpText, setHelpText] = useState(false);
  const [helpToggle, setHelpToggle] = useState(false);


  const addTag = () => {
    if (inputValue==="") {
      setHelpText(true)
    } else {

      let temp = [...question.value, inputValue.trim()]
      withInputChange(temp, question.id);
      setInputValue("");
      setHelpText(false)
    }
    
  };

  const toggleModal = () => {
    window.scrollTo(0, 0);
    setHelpToggle(()=>!helpToggle)
  }

  const changeInput = (e) => {
    setHelpText(true)
    setInputValue(e.target.value)
  }

  const deleteTag = (id) => {
    let temp = question.value.filter((n,i)=> i === id ? false : true)
    withInputChange(temp, question.id);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      addTag()
    }

  }

  


  return (
    <>
      <div className="inputWrap">
        <div className="flexWrapSpaceBet inline">
            <label className="fw5">{question.label}</label>
            {helpText && <p className='helpText'>Click + to add</p>}
          
            <HelpButton clicked={toggleModal}/>
          
          
        </div>

        <div className="flexWrapStart">
        {question.value.map((value, n) => (
          <Tag name={value} id={n} delete={deleteTag} />
        ))}
      </div>
        
        {question.value.length !== 5 && 
        <div className="flexWrapSpaceBet inline">
          <input
            type="text"
            id={question.id}
            value={inputValue}
            onChange={changeInput}
            disabled={question.value.length === 5}
            onKeyUp={(e) => enter(e)}
          ></input>
          <button className='sButton' onClick={addTag} disabled={question.value.length === 5}>+</button>

        </div>
        }

        

      </div>

      <HelpModal question={question} show={helpToggle} clicked={toggleModal}/>

      
    </>
  );
};

export default MultipleInput;
