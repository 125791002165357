export const classData = [  
  {
    id: 1,
    title: "Subject",
    label: "Subject",
    help: {
      intro: "What is the name of the class? ",
      examples: "Design, Maths, IT, History",
      context: "Fiona has enjoyed IT. For John to progress further in History...",
      other: ["This field must be populated."]
    },
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "text",
    value: "",
    options: []
  },
  {
    id: 2,
    title: "ReportType",
    label: "Subject Type",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Select",
    help: {
      intro: "What is your subject type?",
      examples: [
        "General - the subject focuses on understanding and interpretating various topics.",  
        "Science - the subject focuses on understanding topics, developing skills and applying what has been learnt to solve problems.",
        "Art - the subject focuses on understanding topics, developing skills and applying what has been learnt creatively.",
        "Technology - the subject focuses on understanding topics and developing skills through project based learning.",
        "Language - the subject focuses on understanding concepts and developing skills to communicate effectively and creatively.",
        "Sport - the subject focuses on playing sports and developing understanding and skills from playing."
      ],
      context: [
        "General - History, Geography, English Literature",  
        "Science - Maths, Biology, Chemistry, Physics",
        "Art - Art, Music, Drama",
        "Technology - Design Technology, IT, Food Technology",
        "Language - English Language, French, Spanish, Mandarin, Latin, German, Arabic",
        "Sport - Football, Cricket, Badminton, Rugby."
      ],
      other: ["If in doubt use 'General'"]
    },
    value: "General",
    options: ["General", "Science","Drama","Art","Technology","Language",'Sport']
  },
  {
    id: 3,
    title: "Timeframe",
    label: "Report Period",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Select",
    help: {
      intro: "What is the review period of the report?",
      examples: ["Term", "Year", "Month", "Course"],
      context: ["He has had an excellent _____.",  "Over the _____, she has progressed well"],
      other: []
    },
    value: "Term",
    options: ["Term", "Year", "Month", "Course"]
  },
  
  {
    id: 4,
    title: "Focus",
    label: "Focus",
    condition: ['General','Sport','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Select",
    help: {
      intro: "What focus would you like the report to have?",
      examples: ["General - will focus the report on general development.",  
                "Exam  - will focus the report on how ready a student is for an examination.",  
                "Project (Coming Soon) - will focus the report on the students delivery and quality of a project.",
                ],
      context: [],
      other: ["If in doubt use 'General'"]
    },
    value: "General",
    options: ["General", "Exam"]
  },
  {
    id:5,
    title: "TimetoExam",
    label: "Time to Exam",
    condition: ['General','Art','Drama','Technology','Science','Language'],
    focus: [],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Lot of time", "", "Exam Imminent"],
    help: {
      intro: "How long does the student have to prepare for the exam?",
      examples: [],
      context: ["He still has plenty of time to address his gap in knowlegde", "With the exam imminent, she should focus on practices her exam technique."],
      other: []
    }
  },
  {
    id:6,
    title: "TimetoDelivery",
    label: "Project Stage",
    condition: ['General','Art','Drama','Technology','Science','Language'],
    focus: ['Project'],
    type: "Slider",
    value: "3",
    options: [],
    labels: ["Ideation", "Make", "Completed"],
    help: {
      intro: "How long does the student have to deliver their project?",
      examples: [],
      context: ["With his project due imminently, he must increase his effort in order to deliver on time.", "Her project is nearing completion and I do not foresee any issue with her meeting the deadline."],
      other: []
    }
  },
  {
    id: 7,
    title: "Sports",
    label: "Sports",
    condition: ['Sport'],
    focus: ['General','Exam'],
    type: "Multiple",
    help: {
      intro: "What did the students play in class?",
      examples: ["Topics should be a noun","Maximum of 3", "2 Topics are combine with 'and'", "3 Topics are combine with a ',' and a 'and'"],
      context: ["Nick enjoyed playing  _____.",  
      "She has a solid understanding of _____ and _____.",
      "Her grasp of _____ demonstrated a natural aptitude for sports.",
    
    ],

      other: []
    },
    value: [],
    options: []
  },
  {
    id: 8,
    title: "Topics",
    label: "Topics",
    condition: ['General','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam','Project'],
    type: "Multiple",
    help: {
      intro: "What topics or knowledge areas did your students learn during your class?",
      examples: ["Topics should be a noun","Maximum of 3", "2 Topics are combine with 'and'", "3 Topics are combine with a ',' and a 'and'"],
      context: ["Nick enjoyed learning about _____.",  
      "She has a solid understanding of _____ and _____.",
      "Her written work has been excellent demonstrating her grasp of _____.",
      "His understanding of _____ is still shallow at this stage",
      "Liz has learnt and applied her understanding of _____ and _____.",
      "John has understood _____ but is still struggling with _____.",
    
    ],

      other: []
    },
    value: [],
    options: []
  },
  {
    id: 9,
    title: "Skills",
    label: "Skills",
    condition: ['General','Art','Drama','Technology','Science','Language','Sport'],
    focus: ['General','Exam','Project'],
    type: "Multiple",
    help: {
      intro: "What skills did your students learn during your class?",
      examples: ["Skills should be a present verb or a noun that works with below examples", "E.g. Acting, Writing, Deduction, Sketching, Football, Tennis", "Maximum of 3", "2 Skills are combine with 'and'", "3 Skills are combine with a ',' and a 'and'"],
      context: ["His _____ and _____ skills have shown impressive development.", "She has excelled at _____ this year.", "John showed excellent ability when using his _____, _____ and _____ skills."],
      other: []
    },
    value: [],
    options: []
  },
  {
    id: 10,
    title: "Projects",
    label: "Assignments",
    condition: ['General','Art','Drama','Technology','Science','Language'],
    focus: ['General','Exam'],
    type: "Multiple",
    help: {
      intro: "What assignments/projects did your students undertake during the period that can be refered to as an example of the students ability?",
      examples: ["Projects should be a noun","Maximum of 3", "2 Projects are combine with 'and'", "3 Projects are combine with a ',' and a 'and'"],
      context: ["Jane worked very hard on his _____, she should be proud of her efforts.",  "I thought his _____ showed how far he has developed this term.",  "Kate's _____ blew me away"],
      other: ['This will be the focus of a project report type']},
    value: [],
    options: []
  },

]
