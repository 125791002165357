import React from "react";
import { IoClose } from "react-icons/io5";


import "./tag.css";

export default function Tag(props) {

  const handleDelete = (id) => {
    props.delete(id)
  };

  

  return (
        <div className='Tag'>
          <div className='Tag-label'>
            {props.name} 
          </div>
          <div className='cSButton flexWrap' onClick={() => handleDelete(props.id)} >
            <IoClose/>
          </div>
        </div>
    
  );
}
