import React from "react";
import "./textArea.css";

const TextAreaInput = ({ question, withInputChange }) => {
  return (
    <div className="inputWrap">
      <label className="fw5">{question.title}</label>
      <textarea
        id={question.id}
        value={question.value}
        onChange={(e) => withInputChange(e.target.value, question.id)}
      ></textarea>
    </div>
  );
};

export default TextAreaInput;
