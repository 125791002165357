
import React from "react";


const CheckBox = (props) => {


    const update = () => {
        props.change(props.id)
    }


  return (

    <label className="checkcontainer"  id={props.id}>{props.option.label}
        <input type="checkbox" checked={props.option.check} onChange={update}/>
        <span className="checkmark"></span>
    </label>

    )}

export default CheckBox;