import React from 'react';
import './footer.css';

const Footer = (({toggleModal}) => {



 return (
     <div className="footer">


         <div className='flexWrapSpaceBet w-hun'>

            <p>© 2021 N Green</p>
            <p></p>
            <p onClick={toggleModal} className='hover pointer'>About</p>

        </div>
         
     </div>
 )

})

export default Footer;
