// import abilityData from "../Data/Ability.json";
// import effortData from "../Data/Effort.json";
import {Engagement} from "../Data/Engagement";
import {Progress} from "../Data/Improvement";
import {Effort} from "../Data/Effort";
import {Attainment} from "../Data/Attainment";
import {Steps} from "../Data/Steps";
import {Exam} from "../Data/Exam";
// import improvementData from "../Data/Improvement.json";
import {Vocab} from "../Data/Vocab";

import * as pureFn from "./pureFns";


export const getClassDefaults = (value,checktype) => {

    //Function takes report type and updates
    return Vocab[checktype][value.toLowerCase()]

}


export const getSentenceOptions = (classQs,studentQs,report,intro) => {

    let sentenceObj = {
        Engagement: Engagement,
        Steps: Steps,
        Attainment: Attainment,
        Progress: Progress,
        Exam: Exam,
        Effort: Effort
    }

    

    
    

    // Refine question objects into value objects

    let studentObj = {}
    let classObj = {}

    studentQs.forEach(element => {
        studentObj[element.title.replace(/\s/g, '')] = element.value
    });
    
    classQs.forEach(element => {
        classObj[element.title.replace(/\s/g, '')] = element.value
    });

    let reportState = pureFn.buildReportStateObj(classObj["ReportType"],classObj["Focus"],studentObj.KnowledgeDev,studentObj.SkillDev,studentObj.CommonDev,false)
    let keyWordObj = pureFn.buildKeyWordObj(classObj,studentObj,Vocab)
    const nameObj = pureFn.buildNameObj(studentObj.Firstname, studentObj.Sex)

    // nameObj.name = nameObj.nameAlt


    let newOptions = sentenceObj[report].filter((sentenceObj)=>{               
                        
        return pureFn.validateSentence(sentenceObj, studentObj, classObj, reportState)

    })

    return newOptions.map((option)=>{
        return pureFn.convertString(option.Text,nameObj,keyWordObj,studentObj,classObj)
    })

}




export const reportCalulation = (classQs,studentQs) => {


    // Data used for reports

    // sentenceObj = used when selecting sentence based on order

        let sentenceObj = {
            Engagement: Engagement,
            Steps: Steps,
            Attainment: Attainment,
            Progress: Progress,
            Exam: Exam,
            Effort: Effort
        }

        let studentObj = {}
        let classObj = {}

        studentQs.forEach(element => {
            studentObj[element.title.replace(/\s/g, '')] = element.value
        });
        
        classQs.forEach(element => {
            classObj[element.title.replace(/\s/g, '')] = element.value
        });


        const nameObj = pureFn.buildNameObj(studentObj.Firstname, studentObj.Sex)
        let keyWordObj = pureFn.buildKeyWordObj(classObj,studentObj,Vocab)
        let reportState = pureFn.buildReportStateObj(classObj["ReportType"],classObj["Focus"],studentObj.KnowledgeDev,studentObj.SkillDev,studentObj.CommonDev)
        let reportOrder = pureFn.determinReportOrder(studentObj,classObj["Focus"])

    // Function will take an array of options, validate based on condition and pupil and return array of text strings for use.  The function uses side effect of report state

        const filterOptions = (studentObj, classObj, options, reportState, i) => {
                    
                    // Produce an array of viable sentences


                    let newOptions = options.filter((sentenceObj)=>{  
                        
                        
                        
                        return pureFn.validateSentence(sentenceObj, studentObj, classObj, reportState)

                    })

                    // Pick 1 at random

                    let finalOption = pureFn.selectRandom(newOptions)



                    // if no options set to an empty string

                    if (finalOption == null){
                        return {Text: ""}
                    }
                    // Update ReportState - Step required to avoid duplication and enables future report construction

                    if (reportState.Intro === true ) {
                        reportState.Intro = false
                    }
                    

                    if (finalOption.Opinion) {
                        reportState.Opinion = true
                    }

                    if (/STRSKILLS/g.test(finalOption.Text)) {
                        reportState.Strskills = true
                    }
                    if (/WKSKILLS/g.test(finalOption.Text)) {
                        reportState.Wkskills = true
                    }

                    if (/STRTOPICS/g.test(finalOption.Text)) {
                        reportState.Strtopics = true
                    }
                    if (/WKTOPICS/g.test(finalOption.Text)) {
                        reportState.Wktopics = true
                    }

                    if (/COMFEED/g.test(finalOption.Text)) {
                        reportState.Comfeed = true
                    }



                    return finalOption

        }

        let chosen = reportOrder.reduce((acc,report,i)=> {

            

            let sentObj = filterOptions(studentObj,classObj,sentenceObj[report],reportState, i)


            sentObj.SentenceType = report
            sentObj.StudentValue = studentObj[report]


            return [...acc, sentObj]  

        },[])


        let randomString =  chosen.reduce((acc, sentence, i,arr)=>{

                let string = ""

                if(i === 0){
                    string = sentence.Text
                }else{

                    if(+arr[i].StudentValue<3 && +arr[i-1].StudentValue>2){

                        let conectArr = ["  However, ","  Yet, ","  That said, ","  Though, "]

                        string = pureFn.selectRandom(conectArr) + sentence.Text
                    }else{
                        string = "  " + sentence.Text
                    }


                }

                    



            

                // let string = (i===0) ? sentence.Text : "  " + sentence.Text
    
                
                if (i===0 && !sentence.Termexc) {
    
    
                    const regex = /TIME/g;
                    if(regex.test(string)){
                        
                    } else {
                        string = string.slice(0,-1) + " this [TIME]."
                    }
    
    
                }
    
    
    
                return acc + string 
    
    
        },"")



        return pureFn.convertString(randomString,nameObj,keyWordObj,studentObj,classObj)

   


}