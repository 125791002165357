

export const Engagement = [
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [3,4,5],
                     Engagement: [2,3,4,5],
                    }],
        Text:  "I have been [IMPRESSION] with [NAME]’s [CHARACTER] approach to [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                     Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is [CHARACTER] and listens carefully to advice."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                     Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is a [CHARACTER] student who is consistently trying to develop [NAMECONNECT] skillset which is admirable."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [4,5],
                     Engagement: [3,4,5],

                    }],
        Text:  "[NAME] has consistently proven [NAMESELF] to be a [CHARACTER] student."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: false,
        Patterns : [{
                    Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is a [CHARACTER] student.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: false,
        Patterns : [{
                     Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is a [CHARACTER] learner."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: false,
        Patterns : [{
                    Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is [CHARACTER] during class exercises."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                    }],
        Text:  "[NAME] is [CHARACTER] in [NAMECONNECT] approach to [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [3]
                    }],
        Text: "[NAME] listens reflectively.",
    }, 
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Teamwork: [4,5]
                    },
                    {
                        Engagement: [3],
                    }],
        Text: "[NAME] listens to the ideas, opinions, and comments of others without interrupting.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2]
                    }],
        Text: '[NAMECONNECT] enthusiasm for the subject seems to have waned as the [TIME] has progressed.',
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [3,4,5],
                        Effort:[3,4,5]
                    }],
        Text: "[NAME] is [ENGAGE] during class.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [3,4,5]
                    }],
        Text: "[NAME] is a [ENGAGE] participant in group exercises.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [3,4,5]
                    }],
        Text: "[NAME] is an active listener and important member of the group.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [3,4,5]
                    }],
        Text: "[NAME] asks and answers questions that demonstrate understanding.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5]
                    }],
        Text: "[NAME] asks questions or seeks clarification when needed.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [4,5]
                    }],
        Text: "[NAME] listens well and shares ideas frequently.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [3],
                    }],
        Text: "[NAME] listens well and is reflective during class activities.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [4,5]
                    }],
        Text:  "[NAME] conveys [NAMECONNECT] thoughts and ideas clearly.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2]
                    }],
        Text:  "[NAME] can be engaged in [NAMECONNECT] approach to developing skills but in other instances lacks focus.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5]
                    }],
        Text:  "[NAME] has impressed with [NAMECONNECT] curiosity and engagement with [SUBJECT].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5]
                    }],
        Text:  "[NAME] shows enthusiasm for all aspects of [SUBJECT], especially when learning and refining [NAMECONNECT] [STRSKILLS] skills.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:   "[NAME] has impressed with [NAMECONNECT] curiosity and engagement when developing [NAMECONNECT] [STRSKILLS] skills.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Attainment:[3,4,5]
                    }],
        Text:    "[NAME] works quietly and I urge [NAMEREF] to speak up more as I think others could learn alot from [NAMECONNECT] insights."
    },
    {
        ReportType: ['Science','Art','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Attainment:[3,4,5],
                        Progress:[3,4,5],
                    }],
        Text:    "I have seen [NAMECONNECT] confidence grow as [NAME] becomes more self-reliant when using [NAMECONNECT] [STRSKILLS] skills."
    },
    {
        ReportType: ['Art','Drama'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Attainment:[3,4,5],
                        Progress:[3,4,5],
                    }],
        Text:    "I have seen [NAMECONNECT] confidence grow due to [NAMECONNECT] willingness to take more risks within [NAMECONNECT] [OUTPUTONLY]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:    "[NAME] has shown [APPROACH] when learning [STRTOPICS].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:    "[NAME] really enjoyed learning about [STRTOPICS].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Attainment: [1,2,3],
                    }],
        Text:    "[NAME] is not always fully focused when learning about [STRTOPICS] and this has impacted [NAMECONNECT] current understanding.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    },
                    {
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:    "[NAME] has shown [APPROACH] when working on [NAMECONNECT] [PROJECT] project.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    },
                    {
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:    "[NAME] has shown [APPROACH] when working on [SUBJECT] challenges.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [4,5],
                        Effort: [3,4,5],
                    }],
        Text:    "[NAME] is [ENGAGE] and listens carefully to advice to ensure [NAMECONNECT] work is completed to the highest standard.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:    "I have been very pleased with [NAMEACTUAL]’s [APPROACH]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                    }],
        Text:    "[NAME] continues to lack engagement within my lessons which is clearly disappointing."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:    "[NAME] lacks engagement during [SUBJECT] lessons and often uses the time to engage in other activities."
    },
    {
        ReportType: ['Project'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Progress: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:    "[NAME] must be more disciplined with [NAMESELF] or [NAMECONNECT] [PROJECT] project will not be completed to standard [NAME] is capable of."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     "[NAME] appears to have enjoyed [SUBJECT]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [4,5],
                        Creativity: [3,4,5],
                    }],
        Text:     "[NAMECONNECT] confidence in [SUBJECT] is wonderful to observe."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     "[NAMECONNECT] [APPROACH] for [SUBJECT] exercises is wonderful to observe."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    },
                    {
                        Engagement: [3],
                        Effort: [3,4,5],
                    }],
        Text:     "[NAME] works with [APPROACH] while using [NAMECONNECT] [STRSKILLS] skills to complete [SUBJECT] challenges."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     "[NAME] has approached [SUBJECT] with [APPROACH]."
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     "[NAME] has shown [APPROACH] for developing [NAMECONNECT] skills and learning new topics."
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     '[NAME] has shown [APPROACH] while learning and applying [NAMECONNECT] [STRSKILLS] skills.'
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:     '[NAME] shows [APPROACH] for all aspects of [SUBJECT].'
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:     "In some instances [NAME] lacks focus, most notably when freedom to work independently is given.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Attainment: [1,2],
                        Confidence: [1,2,3]
                    }],
        Text:     "[NAME] would benefit from asking more questions in class.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Attainment: [1,2,3],
                        Effort: [3,4,5],
                    }],
        Text:     "Though, I would encourage [NAMEREF] to ask for more questions in class when [NAME] is uncertain as [NAME] quietly perseveres.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Attainment: [4,5],
                    }],
        Text:     "[NAME] is a [ENGAGE] student who has had a [ABILITY] [TIME]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2,3],
                        Attainment: [1,2,3]
                    }],
        Text:     "I would like to see [NAMEACTUAL] have the confidence to ask for help if [NAME] feels [NAME] needs it."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5]
                    }],
        Text:     "[NAMECONNECT] inquisitive attitude and insightful thoughts during class activities have been a pleasure to observe."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2]
                    }],
        Text:     "[NAME] seems to have lacked motivation and commitment recently."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5]
                    }],
        Text:     "[NAME] approaches each [SUBJECT] challenge with an excellent attitude and maturity.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2]
                    }],
        Text:     "[NAMECONNECT] attitude and maturity in class could be improved upon.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                    }],
        Text:     "I have been delighted with [NAME]’s enthusiasm and continued focus.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Effort: [3,4,5],
                    }],
        Text:     "[NAME] has been quietly attentive in [SUBJECT].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [3,4,5],
                        Effort: [4,5],
                    }],
        Text:     "It has truly been a pleasure getting to know [NAME].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [3,4,5],
                        Effort: [4,5],
                    }],
        Text:   "It has been a pleasure having the opportunity to work with [NAME].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Attainment: [1,2,3],
                        Effort: [1,2,3],
                    }],
        Text:   "I would encourage [NAMEACTUAL] to ask for help more when [NAME] does not understand a concept.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                        Teamwork: [1,2,3]
                    }],
        Text:   "[NAME] disrupts and very often interrupts others during class.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Attainment: [1,2],
                    }],
        Text:   "[NAMECONNECT] current difficulty with [STRSKILLS] would be alleviated significantly by applying [NAMESELF] in class.",
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text:   "[NAME] rarely participates in class activities.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text:   "It’s challenging for [NAMEACTUAL] to complete tasks without constant supervision.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                        Effort: [1,2,3],
                    }],
        Text:   "[NAME] distracts others often and requires frequent reminders to remain on task.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [3,4,5],
                    }],
        Text:   "[NAME] is always willing to contribute in lessons and collaborates well with others.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [3,4,5],
                    }],
        Text:   "[NAME] is always engaged in lessons and makes regular contributions to class learning, which makes [NAMEREF] a pleasure to teach.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        Attainment: [3,4,5]
                    }],
        Text:   "[NAME] is engaged in lessons and makes regular contributions to class learning, which has helped strengthen [NAMECONNECT] [LEARNCONTEXT].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2,3],
                        Effort: [2,3,4]
                    }],
        Text:   "I would like [NAMEREF] to contribute a little more, as this will help strengthen [NAMECONNECT] [LEARNCONTEXT].",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2]
                    }],
        Text:   "[NAME] has proved to be challenging at times.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:   "[NAME] is often distracted by other activities in class.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5]
                    }],
        Text:   "I have been very impressed with the approach that [NAME] has taken to the various [SUBJECT] challenges.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2,4,5],
                    }],
        Text:   "[NAME] has shown [APPROACH] when working on [NAMECONNECT] [PROJECT] project in class.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text:   "[NAME] is a pleasure to teach when fully engaged, sadly [NAME] can easily be distracted in class.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Creativity: [3,4,5]
                    }],
        Text:   "[NAME] has frequently demonstrated [NAMECONNECT] curiosity and creativity during [SUBJECT] activities.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2],
                    }],
        Text:   "At times [NAME] can lose focus when carrying out practical work.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:   "[NAME]'s enthusiasm for [SUBJECT] has been evident this [TIME], especially when using [NAMECONNECT] [STRSKILLS] skills.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Creativity: [4,5],
                    }],
        Text:   "There have been some excellent moments where [NAME] has applied [NAMESELF] well, sadly this can be overshadowed by distraction.",
    },

    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Creativity: [4,5],
                        Attainment: [3,4,5],
                    }],
        Text:   "It is clear that [NAME] enjoys the practical side of [SUBJECT] as [NAMECONNECT] [ABILITY] [PROJECT] project demonstrates.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Creativity: [4,5],
                        Attainment: [3,4,5],
                    }],
        Text:   "It is clear that [NAME] enjoys the practical side of [SUBJECT] as [NAMECONNECT] [APPROACH] demonstrates when working on class challenges.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                        Attainment: [1,2,3],
                    }],
        Text:   "[NAME] must ensure [NAME] remains fully focussed at all times if [NAMECONNECT] skills and understanding are to improve.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                        Creativity: [3,4,5],
                    }],
        Text:   "[NAME] has shown a high level of creativity but at times [NAME] could be more self-regulated.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                        Effort: [1,2],
                    }],
        Text:   "Disruptive behaviour and lateness have occasionally featured which will prevent [NAMEREF] from reaching [NAMECONNECT] full potential.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text:   "[NAMECONNECT] behaviour has occasionally inhibited [NAMEREF] from reaching [NAMECONNECT] full potential.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                        Creativity: [3,4,5],
                    }],
        Text:   "[NAME] has strong creativity but [NAMECONNECT] behaviour is limiting [NAMEREF] from utilising it well.",
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        Creativity: [3,4,5],
                    }],
        Text:   "[NAME] has demonstrated creativity and curiosity when carrying out tasks.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Attainment: [3,4,5],
                        Effort: [3,4,5],
                    }],
        Text:   "It is a shame that [NAMECONNECT] work at times is overshadowed by [NAMECONNECT] behaviour.",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:   "[NAME] has worked with enthusiasm when learning [STRSKILLS],",
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        Teamwork: [4,5]

                    }],
        Text:      "[NAME] tackles new challenges with [APPROACH] and works well in a team."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] is tackling new challenges with [APPROACH]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] is a [ENGAGE] learner who tackles new challenges with [APPROACH]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:      "[NAME] seems to tackle new challenges with [APPROACH]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] tackles new challenges with [APPROACH]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] has a [ENGAGE] attitude during class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] has a [ENGAGE] attitude when participating in class exercises."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:      "When participating in class exercises, [NAME] rarely shows the engagement level that will make learning easier for [NAMEREF]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [4,5],
                        Effort: [4,5],
                    }],
        Text:      "[NAME] leads by example and is a positive example for the whole class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [4,5],
                    }],
        Text:      "[NAME] works well with others and shows respect to everyone."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [4,5],
                    }],
        Text:      "[NAME] takes an active role in class discussions about [STRTOPICS]."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2],
                    }],
        Text:      "[NAME] has difficulty staying on task."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                    }],
        Text:      "[NAME] has difficulty staying on task and needs to work on not distracting others."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:      "[NAME] needs frequent reminders to stay focused throughout lessons."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Teamwork: [1,2],
                    }],
        Text:      "[NAME] sometimes shows inconsiderate behaviour to [NAMECONNECT] classmates."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Progress: [3,4,5],
                    }],
        Text:      "It has been great to see [NAMECONNECT] engagement improve, though, [NAME] occasionally gets distracted."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Teamwork: [3,4,5],
                        Attainment: [3,4,5]
                    }],
        Text:      "[NAME] enjoys participating in class and [NAMECONNECT] knowledge adds a great deal."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Teamwork: [3,4,5],
                    }],
        Text:      "[NAME] is very compassionate and is always kind to others."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Teamwork: [3,4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] quietly conducts [NAMESELF] with maturity."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Teamwork: [3,4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] is quietly assured and respectful of teachers and peers."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Engagement: [3],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] is quietly assured and works independently in class."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] is keen to learn and always asks for help when [NAME] needs help."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] always looks for ways to learn and be helpful in the class."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                        Engagement: [3],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] works independently and takes pride in [NAMECONNECT] work."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3,4,5],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] listens to and follows directions precisely and attentively."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:      "[NAME] needs to look to improve [NAMECONNECT] behaviour and attitude in class with a view to using [NAMECONNECT] time more wisely."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] needs frequent reminders to stay attentive during instructions and lessons."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] often struggles to focus on the task at hand in class."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] needs constant reminders to focus on the task at hand in class."

    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Effort: [1,2],
                    }],
        Text:      "[NAME] has been quiet and respectful in [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Effort: [1,2],
                    }],
        Text:      "[NAME] has been a quiet and respectful student in [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                    }],
        Text:      "[NAME] has demonstrated on numerous occasions [NAMECONNECT] love of learning and [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [3],
                        Effort: [4,5],
                    }],
        Text:      "[NAME] brings a calm approach to [SUBJECT]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2],
                        Effort: [3,4,5],
                    }],
        Text:      "[NAME] must ensure [NAME] remains fully focussed at all times."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        Progress: [3,4,5],
                    }],
        Text:      "[NAME] has stepped up [NAMECONNECT] confidence this term."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [3,4,5],
                        Progress: [3,4,5],
                    }],
        Text:      "[NAME] is not afraid to seek support if [NAME] is unsure."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Effort: [4,5],
                        Progress: [4,5],
                    }],
        Text:      "I have seen [NAMECONNECT] confidence grow as [NAME] becomes more self-reliant with [STRSKILLS]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] needs to work on being independent with [NAMECONNECT] learning, and not just following those around [NAMEREF]."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text:      "[NAME] can be let down by [NAMECONNECT] approach to learning."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Intro',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text:      "[NAME] is a [ENGAGE] member of [NAMECONNECT] [SUBJECT] class."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Attainment: [4,5]
                    }],
        Text:      "[NAME] is always happy to volunteer an answer to show [NAMECONNECT] understanding."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Attainment: [1,2,3]
                    }],
        Text:      "I would like to see [NAMEREF] start to show more curiosity about the subject and challenge [NAMESELF] to really extend [NAMECONNECT] learning."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Attainment: [1,2,3]
                    }],
        Text:      "[NAME] needs to be more actively engaged and focused in all parts of the lesson."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2,3],
                    }],
        Text:      "I urge [NAMEREF] to take a more active role in class, as [NAME] can be a little reticent."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1],
                    }],
        Text:      "[NAMECONNECT] attendance can sometimes be intermittent."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [2,3],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] is a quiet presence and I would like to see [NAMECONNECT] curiosity to participate in lessons develop."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] should endeavour to focus fully throughout our lessons in order to realise [NAMECONNECT] potential."

    },    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "[NAME] does have the tendency to become easily distracted."

    },  
    {
        ReportType: ['Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2,3],
                    }],
        Text:      "I would have liked to see more enthusiasm and drive from [NAMEREF] during group exercises."

    }, 
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [{
                    Engagement: [1,2,3],
                    Attainment: [1,2],
                    }],
        Text: "[NAME] must ensure that [NAME] starts asking more questions to help [NAMEREF] develop and improve [NAMECONNECT] understanding.",

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Effort: [1,2],
                    Engagement: [1,2],
                    }],
        Text:  "[NAME] could have used [NAMECONNECT] time in class more productively."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Attainment: [1,2,3],
                    Effort: [1,2,3],
                    Engagement: [1,2],
                    }],
        Text:  "[NAME] could easily improve [NAMECONNECT] skills further by applying [NAMESELF] more in class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Progress: [3,4,5],
                    Confidence: [3,4,5],
                    Engagement: [4,5],
                    }],
        Text:  "[NAME] is more confident in offering contributions to class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [1,2],
                    }],
        Text:  "[NAME] can also be too chatty and even a little immature at times."
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [4,5],
                    }],
        Text:  "[NAME] engages well in [SUBJECT] lessons and is a popular member of the class."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [4,5],
                    Confidence: [4,5],
                    }],
        Text:  "[NAMECONNECT]' confidence has grown and [NAME] engages well in [SUBJECT] lessons."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [4,5],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] really enjoys the subject and is eager to do well."
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                    Engagement: [4,5],
                    Confidence: [3,4,5],
                    Effort: [3,4,5],
                    }],
        Text:  "[NAME] asks pertinent questions to develop [NAMECONNECT] understanding and seeks support when it is needed."
    },
    {
        ReportType: ['Art','Technology'],
        Position: 'Filler',
        Context: ['General'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Effort: [4,5],
                        Confidence: [1,2,3],
                        Attainment: [3,4,5]
                    }],
        Text: "I would really like [NAMEACTUAL] to believe in [NAMESELF] more as sometimes [NAME] can be hesitant to experiment with ideas.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Confidence: [4,5],
                    }],
        Text: "It has been encouraging to see [NAMECONNECT] taking part with enthusiasm and confidence.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                        Effort: [1,2],
                    }],
        Text: "If [NAME] is to improve, [NAME] must adopt a more studious attitude in lessons.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Confidence: [3,4,5],
                    }],
        Text: "[NAME] is able to offer valid contributions to class discussions and is happy to ask questions to clarify [NAMECONNECT] understanding.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam'],
        Termexc: true,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Confidence: [3,4,5],
                    }],
        Text: "[NAME] shows real interest in [SUBJECT] and often makes perceptive comments in class.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Confidence: [3,4,5],
                    }],
        Text: "I have been impressed by the way in which [NAME] has asked questions in order to clarify and deepen [NAMECONNECT] understanding.",
    
    },
    {
        ReportType: ['Art','Technology','Sport'],
        Position: 'All',
        Context: ['General','Exam','Project'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Confidence: [3,4,5],
                    }],
        Text: "I have been very pleased with the way [NAME] has engaged with learning new processes and techniques.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'All',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text: "[NAME] is an enthusiastic student, who has been a pleasure to teach.",
    
    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text: "[NAME] works very well with others students to create thoughtful and interesting performances.",
    
    },
    
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text: "[NAME] shows excellent curiosity in [NAMECONNECT] approach to new topics.",
    
    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Creativity: [3,4,5]
                    }],
        Text: "[NAME] works very creatively with others, often taking a lead directing scenes.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [1,2],
                    }],
        Text: "[NAME] sometimes undermines the quality of [NAMECONNECT] [OUTPUTONLY] by being unfocused.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text: "[NAME] makes perceptive contributions to discussion.",
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language'],
        Position: 'All',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                    }],
        Text: "I have been really impressed with [NAME]'s engagement and positive contributions to lessons.",
    
    },
    {
        ReportType: ['Drama'],
        Position: 'Filler',
        Context: ['General','Exam'],
        Termexc: false,
        Opinion: true,
        Link: false,
        Connect: false,
        Patterns : [{
                        Engagement: [4,5],
                        Creativity: [4,5],
                    }],
        Text: "[NAME] works creatively with others and often injects thoughtful and imaginative ideas into the devising process.",
    
    },


    
    

]






