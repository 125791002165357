
//exam technigue

export const Exam = [


    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: ['Time'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3,4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAME] spends far too long on [NAMECONNECT] responses to exam style questions and [NAME] simply will not be able to do this in the exam."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAME] has understood the need to use the data from the resources provided when responding to complex questions. "

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAME] is developing effective exam technique to answer complex questions well."

    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "In exam style questions, [NAME] needs to write with greater [COMFEED] and ensure [NAMECONNECT] answers use all the detail provided. "

    },
    {

    ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
    Position: 'Filler',
    Context: ['Exam'],
        Tag: [],
        Termexc: false,
    Skills: false,
    Focus: false,
    Link: false,
    Connect: false,
    Patterns : [{
                    Exam: [4,5],
                        Attainment: [4,5],
                }],
    Text: "[NAME] must ensure that [NAME] includes the [COMFEED] that will enable [NAMEREF] to score maximum marks."

    },
    {

        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAMECONNECT] test results have been excellent and this will only improve as [NAME] develops [NAMECONNECT] exam technique."
    
    },
    {

        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                       Exam: [1,2,3],
                        Attainment: [1,2,3,4],
                    }],
        Text: "There is a need for further exam practice, specifically the tougher more complex questions."
        
    },
    {

        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Attainment: [1,2,3,4],
                    }],
        Text: "[NAME] must work on including [COMFEED] within his exam questions."
        
    },
    {

        ReportType: ['General','Science','Art','Drama','Technology'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: ['Process'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                       Exam: [4,5],
                        Attainment: [5],
                    }],
        Text: "[NAME] just needs to show [NAMECONNECT] thought process is clearly articulated in the more complex questions."
        
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAME] has gained consistently high marks in all end of topic tests demonstrating excellent exam technique."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Exam: [4,5],
                        Progress: [4,5],
                    }],
        Text: "[NAMECONNECT] exam technique has gone from strength to strength."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                        Attainment: [4,5],
                    }],
        Text: "[NAMECONNECT] answers in tests often lack [COMFEED] and so [NAME] will need to work on adding this in [NAMECONNECT] future responses."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                    }],
        Text: "[NAME] is [DEVELOPING] [NAMECONNECT] exam technique and is making better use of [STREXAM] to respond to complex questions."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Exam: [4,5],
                        Progress: [4,5],
                    }],
        Text: "[NAMECONNECT] responses to exam style questions have [DEVELOPED] considerably."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [4,5],
                    }],
        Text: "[NAME] has gained consistently high marks for all the exam style questions that [NAME] has completed."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2],
                    }],
        Text: "[NAMECONNECT] recent test results highlight the need for improved exam preparation and technique."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Examples'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2],
                    }],
        Text: "[NAME] needs ensure examples and key words are included in [NAMECONNECT] responses to get full marks."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: [],
        Termexc: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Exam: [1,2,3],
                        Attainment: [1,2,3],
                    }],
        Text: "[NAMECONNECT] exam technique and knowledge would benefit from reattempting past papers."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: [],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3],
                        Attainment: [1,2,3],
                    }],
        Text: "[NAMECONNECT] exam technique and knowledge would benefit from reattempting past papers."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3],
                        Attainment: [1,2,3],
                    }],
        Text: "[NAME] needs to make even more use of past papers and work on [NAMECONNECT] exam technique."
    
    },

    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: true,
        Connect: true,
        Patterns : [{
                        Exam: [1,2,3],
                        Attainment: [1,2,3],
                    }],
        Text: "[NAME] should look to work on [TECHNIQUE] to improve overall exam preparation."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3,4,5],
                    }],
        Text: "[NAMECONNECT] exam technique is [EXAM] and [NAME] should now focus on [TECHNIQUE] to improve [NAMECONNECT] preparation."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3,4,5],
                    }],
        Text: "[NAMECONNECT] exam technique is [EXAM] and [NAME] should look to work on [TECHNIQUE]."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3,4,5],
                    }],
        Text: "[NAME] needs to continue to work on [NAMECONNECT] exam technique, with a focus on [TECHNIQUE]."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3,4,5],
                    }],
        Text: "With exam technique, [NAMECONNECT] focus should be on [TECHNIQUE]."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam','Mock'],
        Tag: ['Resources'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Exam: [1,2,3],
                    }],
        Text: "[NAME] needs to ensure that [NAMECONNECT] revision includes plenty of exam practice in order to improve [NAMECONNECT] exam technique."
    
    },
    {
        ReportType: ['General','Science','Art','Drama','Technology','Language','Sport'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Link: false,
        Connect: false,
        Patterns : [{
                        Attainment: [3,4,5],
                    }],
        Text: "[NAMECONNECT] mock exam results were [ABILITY], but [NAME] needs to work on [TECHNIQUE]."
    
    },
    {
        ReportType: ['Science','Art','Drama','Technology','Language','General'],
        Position: 'Filler',
        Context: ['Exam'],
        Termexc: false,
        Opinion: false,
        Link: true,
        Connect: true,
        Patterns : [
                    {
                    Progress: [3,4,5],
                    }],
        Text:  "[NAME] has made [PROGRESS] progress with [NAMECONNECT] exam technique.",

    },


]