import React from 'react';


const PicButton = ((props) => {


    const update = () => {

        props.update(props.name)


    }

let classStr = (props.name === props.live) ? 'sButton lmargin flexWrap active'  : 'sButton lmargin flexWrap' 


 return (
     <>

    <button className={classStr} onClick={update}>
        
        {props.children}
    
    </button>
     </>
 )

})

export default PicButton;