import React from "react";
import TextInput from "../textInput/textInput";
import SelectInput from "../selectInput/selectInput";
import TextAreaInput from "../textArea/textArea";
import MultipleInput from "../multipleInput/multipleInput";
import SlideInput from "../sliderInput/sliderInput";
import CheckInput from "../checkInput/checkInput";

export const composeForm = (question, onInputChange) => {
  if (question.type === "Select") {
    return (
      <SelectInput
        key={question.id}
        question={question}
        withInputChange={onInputChange}
      />
    );
  } else if (question.type === "TextArea") {
    return (
      <TextAreaInput
        key={question.id}
        question={question}
        withInputChange={onInputChange}
      />
    );
  } else if (question.type === "Multiple") {
    return (
      <MultipleInput
        key={question.id}
        question={question}
        withInputChange={onInputChange}
      />
    );
  } else if (question.type === "Slider") {
    return (
      <SlideInput
        key={question.id}
        question={question}
        withInputChange={onInputChange}
      />
    );
  } else if (question.type === "Checked") {
    return (
      <CheckInput
      key={question.id}
      question={question}
      withInputChange={onInputChange}
      />
    );
  } else {
    return (
      <TextInput
        key={question.id}
        question={question}
        withInputChange={onInputChange}
      />
    );
  }
};
