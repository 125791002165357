import React from 'react';

import './modal.css';
import { IoClose } from "react-icons/io5";



const Modal = props => {

  let displayModal = props.show ? 'modal' : 'hide modal';
  let displayBackdrop = props.show ? 'backdrop' : 'hide backdrop';


  return (
    <div>
      <div className={displayBackdrop} onClick={props.clicked}></div>
      <div className={displayModal}>
        <div className='iconWrap topRight' onClick={props.clicked} >
        <IoClose/>
        </div>
        {props.children}
      </div>
    </div>
  );
};

  export default React.memo(Modal);
