import React from 'react';


import { RiFootprintFill } from 'react-icons/ri';
import {  GiProgression} from 'react-icons/gi';
import { GiWeightLiftingUp } from 'react-icons/gi';
import { BiTrophy } from 'react-icons/bi';
import { RiChatSmile2Line } from 'react-icons/ri';
import { RiFileList2Line } from 'react-icons/ri';
import PicButton from '../Components/PicButton/PicButton';







const OptionList = ((props) => {


  const updateOptions = (e) => {

    props.getOptions(e)
    props.setListSection(e)

  


  }



 return (


    



    <div className="card sPad flexWrapSpaceBetCol">
        <div>
          <div className='topRight hover pointer' onClick={()=>props.setShowReport()}><RiFileList2Line/></div>
          <h2>Options</h2>
          <div className="flexWrap space">
          <PicButton name="Attainment" update={updateOptions} live={props.listSection}>
            <BiTrophy/>
          </PicButton>
          <PicButton name="Effort" update={updateOptions} live={props.listSection}>
          <GiWeightLiftingUp />
          </PicButton>
          <PicButton name="Progress" update={updateOptions} live={props.listSection}>
            <GiProgression/>
          </PicButton>
         
          <PicButton name="Engagement" update={updateOptions} live={props.listSection}>
          <RiChatSmile2Line />
          </PicButton>
          <PicButton name="Steps" update={updateOptions} live={props.listSection}>
          <RiFootprintFill/>
          </PicButton>
          </div>
          <ul className='left-align'>
          {props.reportOptions.map((sent)=>{
            return <li>{sent}</li>

          })}

          </ul>

          


        </div>
     </div>
 )

})

export default OptionList;






