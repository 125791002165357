import React, { useState, useEffect, useRef } from "react";

import {classData} from "../Data/classQuestions";
import {pupilData} from "../Data/pupilQuestions";
import {reportCalulation, getSentenceOptions, getClassDefaults} from "../Caculations/reportCalc";

import * as ValFn from "../Components/Form/validations/formValidations";
import { composeForm } from "../Components/Form/validations/formComposition";
import OptionList from "./OptionList";
import { AiOutlineUnorderedList } from 'react-icons/ai';


export default function ReportForm() {
  const [pupilQuestions, setPupil] = useState(pupilData);
  const [classQuestions, setClass] = useState(classData);
  const [report, setReport] = useState("Click 'Refresh' to generate a report based on data provided. Continue to click 'Refresh' for different options.");
  const textAreaRef = useRef(null);

  const [showReport, setShowReport] = useState(true);
  const [reportOptions, setReportOptions] = useState([])

  const [listSection, setListSection] = useState('Attainment');

  const [helpText, setHelpText] = useState('Click Refresh');
  
  
  useEffect(()=>{

    getOptions(listSection)
    
  },[pupilQuestions])

  useEffect(()=>{

    
    
  },[classQuestions])



  const updatePupilChecks = (value,classId) => {

    // either going to be removed or added

    let topicID = (classId===8) ? 11 : 12

    if(classId===8 || classId === 9){

      if (value.length > pupilQuestions[topicID].value.length){
        // add

        let temp = [...pupilQuestions[topicID].value,{label: value[value.length-1], check: false, value: value[value.length-1]}]
        setPupil(ValFn.updateCheck(pupilQuestions, topicID, temp));

      } else {
      
        let delTemp = pupilQuestions[topicID].value.filter((option,i)=>{

          let tempId = value.indexOf(option.label)
            return (tempId === -1) ? false : true

        })

        setPupil(ValFn.updateCheck(pupilQuestions, topicID, delTemp));


      }
      
      
    }




  }

  const updateAllPupilChecks = (skillvalue,topicvalue,attributevalue) => {


    let skilltemp = skillvalue.map((point)=>{
      return {label: point, check: false, value: point}

    })

    let topictemp = topicvalue.map((point)=>{
      return {label: point, check: false, value: point}

    })

    let attemp = attributevalue.map((point)=>{
      return {label: point, check: false, value: point}

    })


    let temp1 = ValFn.updateCheck(pupilQuestions, 12, skilltemp)
    let temp2 = ValFn.updateCheck(temp1, 11, topictemp)
    let temp3 = ValFn.updateCheck(temp2, 10, attemp)

      
    setPupil(temp3);

  }
  

  function onClassChange(value, id) {
    const matchid = classQuestions.findIndex((question) => {
      return +question.id === +id;
    });



    // if type add defaults to class and update checks

    if(id==+2){
      let temp1 = ValFn.updateCheck(classQuestions, matchid, value);
      let temp2 = ValFn.updateCheck(temp1, 8, getClassDefaults(value,"skills"));
      let temp3 = ValFn.updateCheck(temp2, 7, getClassDefaults(value,"topics"));
      setClass(temp3);
      updateAllPupilChecks(getClassDefaults(value,"skills"), getClassDefaults(value,"topics"), getClassDefaults(value,"attributes"))
    } else {
      setClass(ValFn.updateCheck(classQuestions, matchid, value));
      updatePupilChecks(value, id)
    }

    // if topic or skill update checks individually

      

    
    
  }

  function onPupilChange(value, id) {

    const matchid = pupilQuestions.findIndex((question) => {
      return +question.id === +id;
    });

    setPupil(ValFn.updateCheck(pupilQuestions, matchid, value));
  }

  const process = () => {
    let testString = reportCalulation(classQuestions,pupilQuestions)
    setReport(testString)
    setHelpText('Edit Below or Refresh Again')
    // textAreaRef.current.focus()
  };

  const getOptions = (name) => {
    let options = getSentenceOptions(classQuestions,pupilQuestions,name,false)
    setReportOptions(options)
  }

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    // e.target.focus();
    setHelpText('Copied!')

  }

  function wordCount(s){

    if (s == null){
        return 0
    }
    
    s = s.replace(/(^\s*)|(\s*$)/gi,"");
    s = s.replace(/[ ]{2,}/gi," ");
    s = s.replace(/\n /,"\n");
    return s.split(' ').filter(String).length; 
}

  return (
    <div className='minMargin' >
    <div className="tricontainer">
      <div className="card sPad">
        <h2>Class</h2>
        {classQuestions.map((question) => {

            if  (question.condition.indexOf(classQuestions[1].value)=== -1){
              return null
            } else if (question.focus.indexOf(classQuestions[3].value) === -1){
              return null
            } else if (question.type==='Checked' && question.value.length === 0){
                return null

            }

          return composeForm(question, onClassChange);
          })
          }

      </div>
      <div className="card sPad">
        <h2>Student</h2>
        {pupilQuestions.map((question) => {

          if  (question.condition.indexOf(classQuestions[1].value)=== -1){
            return null
          } else if (question.focus.indexOf(classQuestions[3].value) === -1){
            return null
          } else if (question.type==='Checked' && question.value.length === 0){
              return null

          }

          return composeForm(question, onPupilChange);

          })
          }


      </div>
      {showReport ? 
      
      <div className="card sPad flexWrapSpaceBetCol">
        <div>
        <div className='topRight hover pointer' onClick={()=>setShowReport(!showReport)}><AiOutlineUnorderedList/></div>
          <h2>Report</h2>
          <div className="flexWrap space">
            <button className='aButton' onClick={process}>Refresh</button>
            <button className='aButton' onClick={copyToClipboard}>Copy</button>
          </div>
          <p className="i bold">{helpText}</p>
        </div>
        <textarea className='reportBox' ref={textAreaRef} value={report} onChange={(e)=>setReport(e.target.value)}></textarea>
        <div>
          <p>Words: {wordCount(report)}</p>
          <p>Characters: {report.length ? report.length : 0 }</p>
        </div>
        <div>
          </div>
        
      </div> : 
      <OptionList getOptions={getOptions} setShowReport={()=>setShowReport(!showReport)} reportOptions={reportOptions} listSection={listSection} setListSection={(a)=>setListSection(a)}/>
    
      }
    </div>
    </div>
  );
}
