import React, { useState } from "react";
import "./sliderInput.css";

import HelpModal from "../../HelpModal/helpModal";
import HelpButton from "../helpButton";

const SlideInput = ({ question, withInputChange }) => {

  const [helpToggle, setHelpToggle] = useState(false);

    function valuetext(value) {
        return `Good ${value}`;
      }

      const toggleModal = () => {
        window.scrollTo(0, 0);
        setHelpToggle(()=>!helpToggle)
      }


  return (
    <>
    <div className="inputWrap">
        <div className="flexWrapSpaceBet inline">
          <label>{question.label}</label>
          <HelpButton clicked={toggleModal}/>
        </div>
        <input className="slider" type="range" min="1" max="5" value={+question.value}  id={question.id}  onChange={(e) => withInputChange(e.target.value, e.target.id)}/>     
        <div className='flexWrapSpaceBet sliderLabels'>
            {question.labels.map((label,i) => <p className="w-third cen-align" key={i}>{label}</p>)}
        </div>
    </div>
    <HelpModal question={question} show={helpToggle} clicked={toggleModal}/>
    </>
  );
};

export default SlideInput;

