import React from 'react';

import './modal.css';
import { IoClose } from "react-icons/io5";

// 

const HelpModal = ({show, clicked, question:{label, help: {intro, examples = [], context = [], other}}}) => {


  let displayModal = show ? 'modal' : 'hide modal';
  let displayBackdrop = show ? 'backdrop' : 'hide backdrop';

  return (
    <>
      <div className={displayBackdrop} onClick={clicked}></div>
      <div className={displayModal}>
        <div className='iconWrap topRight hover pointer' onClick={clicked} >
        <IoClose/>
        </div>
        <div className='Card sPad'>
          <h2>{label}</h2>
              <div className='left-align'>
                <p>{intro}</p>

                {context.length>0 && <div>
                    <h5>Report Examples</h5>
                    <ul>
                        {context.map((example,i)=> <li key={i}>{example}</li>)}
                    </ul>
                </div>}
          
                {examples.length>0 && <div>
                    <h5>Additional Information</h5>
                    <ul>
                        {examples.map((example,i)=> <li key={i}>{example}</li>)}
                    </ul>
                </div>}
                
                    
                {other.length>0 && <div>
                        <h5>Considerations</h5>
                    <ul>
                        {other.map((example,i)=> <li key={i}>{example}</li>)}
                    </ul>
                </div>}
            </div>

          
          
          
        </div>
      </div>
    </>
  );
};

  export default React.memo(HelpModal);